<template>
  <!-- 操作会员等级弹窗(新增/编辑) -->
  <div class="vipLevelDialog">
    <!-- 新增和单独编辑对话框 -->
    <c-dialog :title="title" :width="750" :showDialog.sync="openDialog">
      <template #content>
        <el-form :model="levelForm" :rules="rules" ref="form" label-width="110px">
          <div class="x-f">
            <el-form-item label="级别编码" prop="vipLevelNo" key="vipLevelNo">
              <el-input class="vipInput" v-model="levelForm.vipLevelNo" placeholder="请输入级别编码(1-20字符)" maxlength="20"
                size="mini"></el-input>
            </el-form-item>
            <el-form-item label="级别名称" prop="vipLevelName" key="vipLevelName">
              <el-input class="vipInput" v-model="levelForm.vipLevelName" placeholder="请输入级别名称(1-40字符)" maxlength="40"
                style="width: calc(100% - 30px); margin-right: 5px" size="mini"></el-input>
              <el-tooltip placement="top">
                <div slot="content">tip:微信卡包等级名称不能超过4个字符</div>
                <i class="el-icon-question" />
              </el-tooltip>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item prop="price" label="价格方案" key="price">
              <el-select v-model="levelForm.priceCaseId" placeholder="请选择价格方案" filterable clearable size="mini">
                <el-option v-for="item in priceCaseList" :key="item.priceCaseId" :label="item.priceCaseName"
                  :value="item.priceCaseId" />
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="会员品牌" key="vipBrandId" prop="vipBrandId">
              <SelectLocal v-model="levelForm.vipBrandId" :option="
                  $select({
                    key: 'vipBrandList',
                    option: {
                      option: {
                        disabled: levelForm.vipLevelId,
                      },
                    },
                  }).option
                " />
            </el-form-item> -->
          </div>
          <div class="x-f">
            <el-form-item label="门店显示" key="isCanShopAdd">
              <el-radio-group v-model="levelForm.isCanShopAdd">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="储值" key="isCanFill">
              <el-radio-group v-model="levelForm.isCanFill">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="积分" key="isCanScore">
              <el-radio-group v-model="levelForm.isCanScore">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="折扣" key="isCanDiscount">
              <el-radio-group v-model="levelForm.isCanDiscount">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="另类卡合并" key="isCanMerge">
              <el-radio-group v-model="levelForm.isCanMerge">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item>
            <!-- <el-form-item label="微会员级别">
              <el-radio-group v-model="levelForm.isWxVipLevel" @change="changeWxVipLevel">
                <el-radio :label="true">是</el-radio>
                <el-radio :label="false">否</el-radio>
              </el-radio-group>
            </el-form-item> -->
               <el-form-item label="商城默认级别">
            <el-radio-group v-model="levelForm.isDefaultWxVipLevel" :disabled="!levelForm.isWxVipLevel">
              <el-radio :label="true">是</el-radio>
              <el-radio :label="false">否</el-radio>
            </el-radio-group>
          </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="生效日期" prop="begDate" key="begDate">
              <el-date-picker clearable v-model="levelForm.begDate" type="date" placeholder="请选择生效日期"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期" prop="endDate" key="endDate">
              <el-date-picker clearable v-model="levelForm.endDate" type="date" placeholder="请选择结束日期"
                value-format="yyyy-MM-dd" format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </div>

          <el-form-item label="备注" key="remark" class="w100">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="levelForm.remark"
              placeholder="请输入备注(1-80字符)" maxlength="80"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button class="marL10" @click="openDialog = false">取消</el-button>
        <el-button type="primary" class="marL10" @click="addlevel(true)">保存并新增</el-button>
        <el-button type="primary" class="marL10" @click="addlevel(false)">保存</el-button>
      </template>
    </c-dialog>
  </div>
</template>

<script>
import { priceCaseListAPI } from "@/api/system/goodsPrice/priceCase"; //商品价格方案
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { addlevel, updatelevel, getlevelDetail } from "@/api/vip/base/level.js";
import { getFormattedDate } from "@/utils/newDate";
export default {
  name: "VipLevelDialog",
  components: {
    CDialog,
    SelectLocal: () => import("@/components/tablePage/select/select-local/index.vue")
  },
  props:{
    vipBrandId: {
      type: [String,Number],
      default: "",
    },
  },
  data() {
    return {
      title: "新增会员级别",
      dialogFormVisible: true,
      openDialog: false, //弹窗开关
      priceCaseList: [], //价格方案
      //会员级别表单
      levelForm: {
        vipLevelName: "",
        vipLevelNo: "",
        remark: "",
        isCanFill: true,
        isCanScore: true,
        isCanDiscount: true,
        isCanMerge: true,
        isWxVipLevel: true,
        isCanShopAdd: false,
        isDefaultWxVipLevel: false,
        status: 0,
        begDate: "",
        endDate: "",
      },
      //表单校验
      rules: {
        vipLevelName: [
          {
            required: true,
            message: "请输入级别名称",
            trigger: "blur",
          },
        ],
        vipLevelNo: [
          {
            required: true,
            message: "请输入级别编码",
            trigger: ["blur", "change"],
          },
        ],
        begDate: [
          {
            required: true,
            message: "请选择生效日期",
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: true,
            message: "请选择结束日期",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    //初始化弹窗数据
    async initializaData(vipLevelId) {
      this.reset();
      this.openDialog = true;
      const res = await priceCaseListAPI();
      this.priceCaseList = res.rows;
      if (vipLevelId) {
        try {
          let { data } = await getlevelDetail(vipLevelId);
          data.endDate = data.endDate.replace("00:00:00", "");
          data.begDate = data.begDate.replace("00:00:00", "");
          this.levelForm = data;
          this.levelForm.isCanShopAdd = this.levelForm.isCanShopAdd ? true : false;
          this.title = "编辑会员等级";
        } catch (err) { }
      } else {
        this.levelForm.begDate = getFormattedDate(2, "-");
        // 初始化查询，默认结束时间十年后。
        this.levelForm.endDate = "2100-01-01";
        this.title = "新增会员等级";
      }
    },
    //新增、修改对话框确认按钮
    async addlevel(isAdd) {
      try {
        await this.$refs["form"].validate();
        //判断生效日期不能大于结束日期
        if (
          this.levelForm.begDate &&
          this.levelForm.endDate &&
          new Date(this.levelForm.begDate).getTime() >=
          new Date(this.levelForm.endDate).getTime()
        ) {
          return this.$message.error("会员级别结束日期小于生效日期，请修改日期。");
        }
        //判断微会员级别时,级别名称不能大于4个字符
        if (this.levelForm.isWxVipLevel && this.levelForm.vipLevelName.length > 4) {
          return this.$message.error("微信卡包等级名称不能超过4个字符");
        }
        if (this.levelForm.vipLevelId != null) {
          await updatelevel({
            ...this.levelForm,
            begDate: this.levelForm.begDate + " 00:00:00",
            endDate: this.levelForm.endDate + " 00:00:00",
          });
          this.$message({
            message: "修改会员等级成功~",
            type: "success",
          });
        } else {
        this.levelForm.vipBrandId=this.vipBrandId
          await addlevel({
            ...this.levelForm,
            begDate: this.levelForm.begDate + " 00:00:00",
            endDate: this.levelForm.endDate + " 00:00:00",
          });
          this.$message({
            message: "新增会员等级成功~",
            type: "success",
          });
        }
        if (isAdd) {
          this.reset();
          this.levelForm.begDate = getFormattedDate(2, "-");
          // 初始化查询，默认结束时间十年后。
          this.levelForm.endDate = "2100-01-01";
          this.title = "新增会员等级";
        } else {
          this.openDialog = false;
        }
        this.$emit("getList");
      } catch (error) { }
    },
    //切换微会员
    changeWxVipLevel(val) {
      if (!val) {
        this.levelForm.isDefaultWxVipLevel = false;
      }
    },
    // 表单重置
    reset() {
      this.levelForm = {
        vipLevelName: "",
        vipLevelNo: "",
        remark: "",
        isCanFill: true,
        isCanScore: true,
        isCanDiscount: true,
        isCanMerge: true,
        isWxVipLevel: true,
        isCanShopAdd: false,
        isDefaultWxVipLevel: false,
        status: 0,
        begDate: "",
        endDate: "",
      };
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
.vipLevelDialog {
  .el-form {
    margin: 15px 15px 0;
  }
  .el-form-item {
    width: 50%;
    .el-select,
    .el-input {
      width: 100%;
    }
    &.w100 {
      width: 100%;
    }
  }
}
</style>
