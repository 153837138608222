var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        width: "30%",
        visible: _vm.show,
        "before-close": _vm.handleClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "y-c" },
        [
          _vm._t("batchForm"),
          _c(
            "div",
            { staticClass: "x-f-end", staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.batchSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }