<template>
  <div class="wrap x-f" id="wrap">
    <cardTitleCom cardTitle="会员账本" class="vipLedger">
      <template slot="cardContent">
        <div class="padd10">
          <el-table
            :data="tableData"
            tooltip-effect="dark"
            border
            v-loading="loadingTable"
            ref="multipleTable"
            show-summary
            :summary-method="getSummaries"
            :max-height="tableHeight"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column
              label="序号"
              align="center"
              width="60"
              type="index"
            />
            <el-table-column
              v-for="(item, index) in tableColumn"
              :key="index"
              :label="item.label"
              align="center"
              :prop="item.prop"
              :minWidth="item.minWidth"
            />
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>
<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { fcount, isObject, isNumber } from '@/utils'
import { vipAccountBook } from '@/api/vip/base/vipCard'
export default {
  name: 'VipLedger',
  components: {
    cardTitleCom
  },
  data () {
    return {
      summary: ['money', 'balance'],
      tableData: [],
      vipId: undefined,
      loadingTable: false,
      tableColumn: [
        {
          label: '日期',
          prop: 'createTime',
          minWidth: 140
        },
        {
          label: '小票号',
          prop: 'billNo',
          minWidth: 180
        },
        {
          label: '类型',
          prop: 'businessType',
          minWidth: 80
        },
        {
          label: '金额',
          prop: 'money',
          minWidth: 125
        },
        {
          label: '余额',
          prop: 'balance',
          minWidth: 120
        },
        {
          label: '门店',
          prop: 'shopName',
          minWidth: 160
        },
        {
          label: '收银员',
          prop: 'cashierName',
          minWidth: 160
        },
        {
          label: '备注',
          prop: 'remark',
          minWidth: 160
        }
      ],
      vivwH: 0 //页面高度
    }
  },
  created () {
    if (this.$route.query.type === 'Update' && this.$route.query.vipId) {
      this.vipId = this.$route.query.vipId
      this.getList()
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  computed: {
    tableHeight () {
      return this.vivwH - 70
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    getSummaries ({ columns, data } = {}) {
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const sumItem = this.summary?.find(x =>
          isObject(x) ? x.key === column.property : x === column.property
        )
        if (sumItem) {
          const arr = column.property.split('.')
          let values = data.map(x => Number(x[column.property] || 0))
          if (arr?.length === 4) {
            const [p, i, key, s] = arr
            values = data.map(
              x => x[p]?.find?.(x => String(x[key]) === i)?.[s] || 0
            )
          }
          sums[index] = `${values.reduce(
            (p, c) => fcount([p, isNumber(Number(c)) ? Number(c) : 0], '+'),
            0
          )}${isObject(sumItem) ? ` ${sumItem.unit}` : ''}`
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    async getList () {
      try {
        if (this.vipId) {
          this.loadingTable = true
          const res = await vipAccountBook(this.vipId)
          this.tableData = res.data || []
        } else {
          this.$message.error('请先选择会员')
        }
      } catch (error) {
      } finally {
        this.loadingTable = false
      }
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap')?.clientHeight
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  width: 100%;
  .vipLedger {
    width: 100%;
    height: 100%;
  }
}
</style>
