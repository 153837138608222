var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.scoreRuleForm,
            rules: _vm.rules,
            "label-width": "96px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isExamineBtn: true,
              showAudit: false,
              isStopBtn: true,
              id: "topOperatingButton",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditBill,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
              isStop: _vm.getEnable,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.scoreRuleForm.billStatus,
              },
            },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "div",
                    { staticClass: "baseInfoBody" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "billNo",
                            label: "方案编号",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "baseInput",
                            attrs: { disabled: true, placeholder: "方案编号" },
                            model: {
                              value: _vm.scoreRuleForm.billNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "billNo", $$v)
                              },
                              expression: "scoreRuleForm.billNo",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "billRuleName",
                            label: "方案名称",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "baseInput",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "方案名称(1-40字符)",
                              maxlength: "40",
                            },
                            model: {
                              value: _vm.scoreRuleForm.billRuleName,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "billRuleName", $$v)
                              },
                              expression: "scoreRuleForm.billRuleName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "datetime",
                            label: "有效日期",
                            "label-width": "80px",
                          },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "datetimerange",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "default-time": ["00:00:00", "23:59:59"],
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "picker-options": _vm.pickerOptions,
                              disabled: _vm.disabled,
                            },
                            model: {
                              value: _vm.scoreRuleForm.datetime,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "datetime", $$v)
                              },
                              expression: "scoreRuleForm.datetime",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "方案备注", "label-width": "80px" } },
                        [
                          _c("el-input", {
                            staticClass: "remarkinput",
                            attrs: {
                              disabled: _vm.disabled,
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 1 },
                            },
                            model: {
                              value: _vm.scoreRuleForm.billRemark,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "billRemark", $$v)
                              },
                              expression: "scoreRuleForm.billRemark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { "label-width": "0px" } },
                    [
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.scoreRuleForm.isPromoNoScore,
                            callback: function ($$v) {
                              _vm.$set(_vm.scoreRuleForm, "isPromoNoScore", $$v)
                            },
                            expression: "scoreRuleForm.isPromoNoScore",
                          },
                        },
                        [_vm._v("折扣特价促销商品不积分")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.scoreRuleForm.isUsableNoScore,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scoreRuleForm,
                                "isUsableNoScore",
                                $$v
                              )
                            },
                            expression: "scoreRuleForm.isUsableNoScore",
                          },
                        },
                        [_vm._v("储值可用金额不积分")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "marT20 x-f" },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "每消费(订单实付金额)",
                            "label-width": "160px",
                            prop: "eachMoney",
                          },
                        },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "订单实付金额",
                              max: 9999999,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.scoreRuleForm.eachMoney,
                                  _vm.scoreRuleForm,
                                  "eachMoney",
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.scoreRuleForm.eachMoney,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "eachMoney", $$v)
                              },
                              expression: "scoreRuleForm.eachMoney",
                            },
                          }),
                          _c("span", [_vm._v(" 元积 ")]),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "5px", prop: "eachScore" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: _vm.disabled,
                              placeholder: "得积分",
                              max: 9999999,
                            },
                            on: {
                              input: function ($event) {
                                return _vm.limitInputlength(
                                  _vm.scoreRuleForm.eachScore,
                                  _vm.scoreRuleForm,
                                  "eachScore",
                                  true
                                )
                              },
                            },
                            model: {
                              value: _vm.scoreRuleForm.eachScore,
                              callback: function ($$v) {
                                _vm.$set(_vm.scoreRuleForm, "eachScore", $$v)
                              },
                              expression: "scoreRuleForm.eachScore",
                            },
                          }),
                          _c("span", [_vm._v(" 分（积分默认整数）")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用商品" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  [
                    _c("span", { staticClass: "fontS14 marR15" }, [
                      _vm._v("适用商品"),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.scoreRuleForm.goodsRangeType,
                          callback: function ($$v) {
                            _vm.$set(_vm.scoreRuleForm, "goodsRangeType", $$v)
                          },
                          expression: "scoreRuleForm.goodsRangeType",
                        },
                      },
                      _vm._l(
                        _vm.dict.type.vip_goods_range_type,
                        function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.value, attrs: { label: dict.value } },
                            [_vm._v(_vm._s(dict.label))]
                          )
                        }
                      ),
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.scoreRuleForm.goodsRangeType == "2" ||
                              _vm.scoreRuleForm.goodsRangeType == "1",
                            expression:
                              "\n              scoreRuleForm.goodsRangeType == '2' || scoreRuleForm.goodsRangeType == '1'\n            ",
                          },
                        ],
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "marT15 marB15" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openGoodsDialog", {
                                      curTable: "goodsOptions",
                                    })
                                  },
                                },
                              },
                              [_vm._v("指定商品")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent("openBrandDialog", {
                                      curTable: "goodsOptions",
                                    })
                                  },
                                },
                              },
                              [_vm._v("指定品牌")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { disabled: _vm.disabled },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEvent(
                                      "openCategoryDialog",
                                      {
                                        curTable: "goodsOptions",
                                      }
                                    )
                                  },
                                },
                              },
                              [_vm._v("指定类别")]
                            ),
                          ],
                          1
                        ),
                        _c("EditTable", {
                          attrs: { options: _vm.goodsOptions },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "marT15" },
                  [
                    _c("span", { staticClass: "fontS14 marR15" }, [
                      _vm._v("例外商品"),
                    ]),
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 如果按全部商品、或按类别、品牌、标签设置积分时,"
                          ),
                          _c("br"),
                          _vm._v("可以剔除部分例外商品不积分或设置特殊的积分 "),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marT15 marB15" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openGoodsDialog", {
                                  curTable: "exceptionGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定商品")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openBrandDialog", {
                                  curTable: "exceptionGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定品牌")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openCategoryDialog", {
                                  curTable: "exceptionGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定类别")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "exceptionGoodsOptions",
                        attrs: { model: _vm.exceptionGoodsOptions },
                      },
                      [
                        _c("EditTable", {
                          attrs: { options: _vm.exceptionGoodsOptions },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "多倍积分" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "marB15" },
                  [
                    _c("span", { staticClass: "marR15 fontS14" }, [
                      _vm._v("取值规则"),
                    ]),
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 多倍积分互斥：取会员级别、特殊日期、会员生日, "
                          ),
                          _c("br"),
                          _vm._v("三者的最大值； "),
                          _c("br"),
                          _vm._v(
                            "多倍积分叠加：取会员级别、特殊日期、会员生日 "
                          ),
                          _c("br"),
                          _vm._v("任意两者及以上之和。 "),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]),
                    _c(
                      "el-radio-group",
                      {
                        staticClass: "marL15",
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.scoreRuleForm.multScoreMode,
                          callback: function ($$v) {
                            _vm.$set(_vm.scoreRuleForm, "multScoreMode", $$v)
                          },
                          expression: "scoreRuleForm.multScoreMode",
                        },
                      },
                      _vm._l(
                        _vm.dict.type.vip_mult_score_mode,
                        function (dict) {
                          return _c(
                            "el-radio",
                            { key: dict.value, attrs: { label: dict.value } },
                            [_vm._v(_vm._s(dict.label))]
                          )
                        }
                      ),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "marB15",
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.scoreRuleForm.isMultVipLevelScore,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.scoreRuleForm,
                              "isMultVipLevelScore",
                              $$v
                            )
                          },
                          expression: "scoreRuleForm.isMultVipLevelScore",
                        },
                      },
                      [_vm._v(" 会员级别多倍积分")]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.scoreRuleForm.isMultVipLevelScore,
                            expression: "scoreRuleForm.isMultVipLevelScore",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "marB15",
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openVipLevelDialog", {
                                  curTable: "multiVipLevelOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定会员等级")]
                        ),
                        _c(
                          "el-form",
                          {
                            ref: "multiVipLevelOptions",
                            attrs: { model: _vm.multiVipLevelOptions },
                          },
                          [
                            _c("EditTable", {
                              attrs: { options: _vm.multiVipLevelOptions },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        staticClass: "marB15",
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.scoreRuleForm.isMultSpecialDateScore,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.scoreRuleForm,
                              "isMultSpecialDateScore",
                              $$v
                            )
                          },
                          expression: "scoreRuleForm.isMultSpecialDateScore",
                        },
                      },
                      [_vm._v("会员特殊日期多倍积分")]
                    ),
                    _vm.scoreRuleForm.isMultSpecialDateScore
                      ? _c(
                          "div",
                          [
                            _c(
                              "div",
                              { staticClass: "x-f" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "marB15",
                                    attrs: { disabled: _vm.disabled },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEvent(
                                          "openTimeDateDialog",
                                          {
                                            curTable: "timeDateOptions",
                                          }
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("选择日期")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fontSize14 marR10 marL10 marB15",
                                  },
                                  [_vm._v("积分倍数")]
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "0px",
                                      prop: "specialDateScoreMult",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "inputWidth",
                                      attrs: { disabled: _vm.disabled },
                                      model: {
                                        value:
                                          _vm.scoreRuleForm
                                            .specialDateScoreMult,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.scoreRuleForm,
                                            "specialDateScoreMult",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "scoreRuleForm.specialDateScoreMult",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("EditTable", {
                              attrs: { options: _vm.timeDateOptions },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-checkbox",
                      {
                        attrs: { disabled: _vm.disabled },
                        model: {
                          value: _vm.scoreRuleForm.isMultBirthdayScore,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.scoreRuleForm,
                              "isMultBirthdayScore",
                              $$v
                            )
                          },
                          expression: "scoreRuleForm.isMultBirthdayScore",
                        },
                      },
                      [_vm._v(" 会员生日多倍积分")]
                    ),
                    _vm.scoreRuleForm.isMultBirthdayScore
                      ? _c(
                          "div",
                          { staticClass: "x-f" },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: { disabled: _vm.disabled },
                                model: {
                                  value: _vm.scoreRuleForm.birthdayScoreMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.scoreRuleForm,
                                      "birthdayScoreMode",
                                      $$v
                                    )
                                  },
                                  expression: "scoreRuleForm.birthdayScoreMode",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.birthday_score_mode,
                                function (dict) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: dict.value,
                                      attrs: { label: dict.value },
                                    },
                                    [_vm._v(_vm._s(dict.label))]
                                  )
                                }
                              ),
                              1
                            ),
                            _c("span", { staticClass: "fontS14 marL15" }, [
                              _vm._v("积分倍数"),
                            ]),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  "label-width": "0px",
                                  prop: "birthdayScoreMult",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "inputWidth marL15",
                                  attrs: {
                                    disabled: _vm.disabled,
                                    placeholder: "积分倍数",
                                  },
                                  model: {
                                    value: _vm.scoreRuleForm.birthdayScoreMult,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.scoreRuleForm,
                                        "birthdayScoreMult",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "scoreRuleForm.birthdayScoreMult",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "marT15" },
                  [
                    _c("span", { staticClass: "fontS14 marR15" }, [
                      _vm._v("例外商品"),
                    ]),
                    _c("el-tooltip", { attrs: { placement: "top" } }, [
                      _c(
                        "div",
                        { attrs: { slot: "content" }, slot: "content" },
                        [
                          _vm._v(
                            " 如果按全部商品、或按类别、品牌、标签设置积分时,"
                          ),
                          _c("br"),
                          _vm._v("可以剔除部分例外商品不积分或设置特殊的积分 "),
                        ]
                      ),
                      _c("i", { staticClass: "el-icon-question" }),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "marT15 marB15" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openGoodsDialog", {
                                  curTable: "multiExcepGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定商品")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openBrandDialog", {
                                  curTable: "multiExcepGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定品牌")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openCategoryDialog", {
                                  curTable: "multiExcepGoodsOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定类别")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form",
                      {
                        ref: "multiExcepGoodsOptions",
                        attrs: { model: _vm.multiExcepGoodsOptions },
                      },
                      [
                        _c("EditTable", {
                          attrs: { options: _vm.multiExcepGoodsOptions },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用渠道" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "适用渠道",
                        "label-width": "80px",
                        prop: "AllowOfflineShop",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.scoreRuleForm.AllowOfflineShop,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scoreRuleForm,
                                "AllowOfflineShop",
                                $$v
                              )
                            },
                            expression: "scoreRuleForm.AllowOfflineShop",
                          },
                        },
                        [
                          _c(
                            "el-checkbox",
                            { attrs: { label: "0", disabled: _vm.disabled } },
                            [_vm._v("线下门店 ")]
                          ),
                          _c(
                            "el-checkbox",
                            { attrs: { label: "1", disabled: _vm.disabled } },
                            [_vm._v("线上商城 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用门店" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用门店", "label-width": "80px" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.scoreRuleForm.shopRangeType,
                            callback: function ($$v) {
                              _vm.$set(_vm.scoreRuleForm, "shopRangeType", $$v)
                            },
                            expression: "scoreRuleForm.shopRangeType",
                          },
                        },
                        _vm._l(
                          _vm.dict.type.fill_shop_range_type,
                          function (dict) {
                            return _c(
                              "el-radio",
                              { key: dict.value, attrs: { label: dict.value } },
                              [_vm._v(_vm._s(dict.label))]
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.scoreRuleForm.shopRangeType == "1" ||
                                _vm.scoreRuleForm.shopRangeType == "2",
                              expression:
                                "\n              scoreRuleForm.shopRangeType == '1' || scoreRuleForm.shopRangeType == '2'\n            ",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marT15 marB15",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEvent("openShopDialog", {
                                    curTable: "shopOptions",
                                  })
                                },
                              },
                            },
                            [_vm._v("指定门店资料")]
                          ),
                          _c("EditTable", {
                            attrs: { options: _vm.shopOptions },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用会员" } },
            [
              _c(
                "template",
                { slot: "cardContent" },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "适用会员", "label-width": "80px" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { disabled: _vm.disabled },
                          model: {
                            value: _vm.scoreRuleForm.vipLevelRangeType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.scoreRuleForm,
                                "vipLevelRangeType",
                                $$v
                              )
                            },
                            expression: "scoreRuleForm.vipLevelRangeType",
                          },
                        },
                        _vm._l(
                          _vm.dict.type.fill_vip_level_range_type,
                          function (dict) {
                            return _c(
                              "el-radio",
                              { key: dict.value, attrs: { label: dict.value } },
                              [_vm._v(_vm._s(dict.label))]
                            )
                          }
                        ),
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                _vm.scoreRuleForm.vipLevelRangeType == "1" ||
                                _vm.scoreRuleForm.vipLevelRangeType == "2",
                              expression:
                                "\n              scoreRuleForm.vipLevelRangeType == '1' ||\n              scoreRuleForm.vipLevelRangeType == '2'\n            ",
                            },
                          ],
                        },
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "marT15 marB15",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEvent("openVipLevelDialog", {
                                    curTable: "vipLevelOptions",
                                  })
                                },
                              },
                            },
                            [_vm._v("指定会员级别")]
                          ),
                          _c("EditTable", {
                            attrs: { options: _vm.vipLevelOptions },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }