<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage"; //表格页面组件
import { listlevel } from "@/api/vip/base/level"; //会员等级
import { listVipCard } from "@/api/vip/base/vipCard";
import { downloadOss } from "@/api/ossfile"; //阿里云接口
export default {
  name: "emitCard",
  components: { TablePage, Dialog },
  data() {
    return {
      options: {
        mutiSelect: true, // 多选
        title: "IC/ID发卡",
        listNo: true, // 序号
        rowKey: "vipId",
        list: this.tableData,
        loading: false,
        total: 0,
        search: [
          {
            type: "filters",
            tip: "会员卡号/会员名称",
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipNos", label: "会员卡号" },
              { filter: "vipNames", label: "会员名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        //按钮
        buttons: [
          {
            click: "add-searchVip",
            label: "选择会员",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "ICHairpin",
            label: "IC 发卡",
            type: "success",
            icon: "el-icon-bank-card",
            disabled: () => !this.tableData.length,
          },
          {
            click: "IDHairpin",
            label: "ID 发卡",
            type: "success",
            icon: "el-icon-bank-card",
            disabled: () => !this.tableData.length,
          },
          {
            click: "add-record",
            label: "发卡记录",
            type: "warning",
            icon: "el-icon-edit-outline",
          },
          {
            click: "add-download",
            label: "发卡工具下载",
            type: "primary",
            icon: "el-icon-download",
            right: true,
          },
        ],
        pagination: {
          onlySelectTotal: true,
        },
        //表格
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipName",
            label: "会员姓名",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "isCardEmitedName",
            label: "发放",
            minWidth: 120,
            align: "center",
          },
        ],
      },
      dialogOptions: {
        width: 1250,
        show: false,
      },
      tableData: [], //表格当前数据
      allTableData: [], //表格全部数据
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "init":
        case "getList":
          this.options.loading = false;
          this.options.list = this.tableData;
          this.options.total = this.tableData.length;
          break;
        case "search":
          await this.$nextTick();
          // search
          // console.log("this.options.body", this.options.search[0]);
          if (this.options.body?.query) {
            this.tableData = this.allTableData.filter(
              (item) => item.vipNo == this.options.body.query
            );
          } else this.tableData = this.allTableData;
          this.handleEvent("getList");
          break;
        case "add-searchVip": //选择会员
          this.dialogOptions = {
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: {
              treeQuery: "",
              treeApi: listlevel,
              defaultProps: {
                id: "vipLevelId",
                label: "vipLevelName",
              },
              queryParams: {
                vipName: undefined,
              },
              search: {
                tip: "会员卡号/会员名称/手机号",
                model: "",
                filters: [
                  { filter: "query", label: "全部" },
                  { filter: "vipNos", label: "会员卡号" },
                  { filter: "vipNames", label: "会员名称" },
                  { filter: "tels", label: "手机号" },
                ],
              },
              table: {
                loading: false,
                mutiSelect: true,
                selectPlaceholder: "输入会员卡号",
                list: listVipCard,
                rowKey: "vipId",
                searchName: "vipNo",
                rowClick: true,
                check: [],
                pagination: {
                  page: 1,
                  size: 15,
                  cardStatus: 0,
                  isCardEmited: false,
                },
                columns: [
                  {
                    prop: "vipNo",
                    label: "会员卡号",
                    minWidth: 150,
                    align: "center",
                  },
                  {
                    prop: "vipName",
                    label: "会员姓名",
                    minWidth: 150,
                    align: "center",
                  },
                  {
                    prop: "tel",
                    label: "手机",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "vipLevelName",
                    label: "会员级别",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "cardStatusName",
                    label: "状态",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "allBalance",
                    label: "卡内总余额",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "allFillMoney",
                    label: "累计充值金额",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "usableFillMoney",
                    label: "可用充值金额",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "allLargessMoney",
                    label: "累计赠送金额",
                    minWidth: 120,
                    align: "center",
                  },
                  {
                    prop: "usableLargessMoney",
                    label: "可用赠送金额",
                    minWidth: 110,
                    align: "center",
                  },
                  {
                    prop: "allScore",
                    label: "累计积分",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "usableScore",
                    label: "可用积分",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "creditMoney",
                    label: "信用额度",
                    minWidth: 110,
                    align: "center",
                  },
                  {
                    prop: "fillCount",
                    label: "充值次数",
                    minWidth: 110,
                    align: "center",
                  },
                  {
                    prop: "payCount",
                    label: "支付次数",
                    minWidth: 110,
                    align: "center",
                  },
                  {
                    prop: "scoreCount",
                    label: "积分次数",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "isCardEmitedName",
                    label: "发放",
                    minWidth: 110,
                    align: "center",
                  },
                  {
                    prop: "zjm",
                    label: "助记码",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "updateTime",
                    label: "开始使用时间",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "lastUseTime",
                    label: "最近使用时间",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "sexName",
                    label: "性别",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "birthday",
                    label: "会员生日",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "address",
                    label: "地址",
                    minWidth: 160,
                    align: "center",
                  },
                  {
                    prop: "remark",
                    label: "备注",
                    minWidth: 160,
                    align: "center",
                  },
                  // {
                  //   prop: 'createBy',
                  //   label: '发卡人',
                  //   minWidth: 160,
                  //   align: 'center'
                  // },
                  // {
                  //   prop: 'createTime',
                  //   label: '发卡时间',
                  //   minWidth: 160,
                  //   align: 'center'
                  // },
                  // {
                  //   prop: 'updateBy',
                  //   label: '修改人',
                  //   minWidth: 160,
                  //   align: 'center'
                  // },
                  // {
                  //   prop: 'updateTime',
                  //   label: '修改时间',
                  //   minWidth: 160,
                  //   align: 'center'
                  // }
                ],
              },
            },
          };
          break;
        case "dialogChange": //获取会员资料
          let allData = [...this.tableData, ...this.dialogOptions.formData.table.check];
          //去重
          allData = allData.filter((item, index, arr) => {
            return arr.findIndex((obj) => obj.vipId === item.vipId) === index;
          });
          allData = Object.values(
            allData.reduce((acc, obj) => {
              acc[obj.vipId] = obj;
              return acc;
            }, {})
          );
          this.allTableData = allData;
          this.handleEvent("search");
          break;
        case "ICHairpin":
          this.dialogOptions = {
            title: "会员卡 IC 发卡",
            width: 800,
            show: true,
            type: "ICGrant",
            formData: {
              list: this.tableData,
            },
          };
          break;
        case "IDHairpin":
          this.dialogOptions = {
            title: "会员卡 ID 发卡",
            width: 800,
            show: true,
            type: "IDGrant",
            formData: {
              list: this.tableData,
            },
          };
          break;
        case "delTableItem":
          this.tableData = this.tableData.filter(
            (item) => item.vipNo !== this.dialogOptions.formData.delTableItemNo
          );
          this.allTableData = this.allTableData.filter(
            (item) => item.vipNo !== this.dialogOptions.formData.delTableItemNo
          );
          this.handleEvent("getList");
          break;
        case "add-record":
          this.dialogOptions = {
            title: "发卡记录",
            width: 800,
            show: true,
            type: "CardIssuingRecord",
          };
          break;
        case "add-download":
          downloadOss(
            "https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/customFiles/sunyun-utils-app Setup 1.0.1.exe"
          ).then((res) => {
            let blob = new Blob([res]);
            // 获取heads中的filename文件名
            let downloadElement = document.createElement("a");
            // 创建下载的链接
            let href = window.URL.createObjectURL(blob);
            downloadElement.href = href;
            // 下载后文件名
            downloadElement.download = "sunyun-utils-app Setup 1.0.1.exe";
            document.body.appendChild(downloadElement);
            // 点击下载
            downloadElement.click();
            // 下载完成移除元素
            document.body.removeChild(downloadElement);
            // 释放掉blob对象
            window.URL.revokeObjectURL(href);
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.tableBody {
  min-height: 100vh;
  padding: 30px;
  //顶部操作
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    //搜索框
    .search {
      display: flex;
      .searchInput {
        margin-right: 13px;
      }
    }
  }
}
//对话框输入框
.dialogInput {
  width: 200px;
}
.textCard {
  height: 400px;
  width: 100%;
}
</style>
