<template>
  <div class="x-f">
    <el-form-item label="单据编号" required>
      <el-input
          style="width: 160px"
          size="mini"
          :disabled="disabled"
          v-model="documentNum"
          placeholder="单据编号"
      ></el-input>
    </el-form-item>
    <el-form-item label="单据日期" required>
      <el-date-picker
          style="width: 160px"
          size="mini"
          v-model="documentDate"
          type="date"
          placeholder="单据日期"
          :disabled="disabled"
          value-format="yyyy-MM-dd"
      >
      </el-date-picker>
    </el-form-item>

  </div>
</template>

<script>
export default {
  name: 'titleInpu',
  props:{
    documentNum:{
      type: String,
      default: ''
    },
    documentDate:{
      type: String,
      default: ''
    },
    disabled:{
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
