<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="96px">
      <topOperatingButton
        id="topOperatingButton"
        :isExamineBtn="true"
        :showAudit="false"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditForm"
        @getQuit="$router.go(-1)"
      >
        <template v-slot:specialDiyBtn>
          <el-button size="mini" @click="handleEvent('openBatchChangeStatus')">
            批量调整状态
          </el-button>
          <el-button size="mini" @click="handleEvent('batchDel')"> 批量删除 </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="options.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" required>
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" required>
              <el-date-picker
                class="inputWidth"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="['2', '3'].includes(options.billStatus)"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="['2', '3'].includes(options.billStatus)"
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员状态调整列表">
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import {
  stateChangeSave,
  stateChangeUpdate,
  stateChangeUpdateBillStatus,
  stateChangeGetDetail,
} from "@/api/vip/bill/stateChange";
import { getBillNo } from "@/api/codeRule";
import { uniqWith } from "lodash"; //去重
import { getFormattedDate } from "@/utils/newDate";

export default {
  name: "StateChangeDetail",
  dicts: ["vip_card_status"],
  components: {
    cardTitleCom,
    EditTable,
    topOperatingButton,
    Dialog,
  },
  watch: {
    "options.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.options.columns[0].disabled = disabledColumns;
        this.options.columns[7].disabled = disabledColumns;
        this.options.columns[8].disabled = disabledColumns;
        this.options.status = val;
      },
      immediate: true,
    },
  },
  data() {
    return {
      loading: false, //遮罩层
      //表格配置
      options: {
        billDate: "", // 单据日期（当前日期）
        billNo: undefined, // 单据编号
        billId: undefined, //单据id
        mutiSelect: true, //是否多选
        status: undefined, //状态
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 0, //表格高度
        retainColumn: true, //保留表格最后一行
        columns: [
          {
            prop: "vipNo",
            label: "会员卡号",
            type: "remoteSelect",
            click: "clickVip",
            minWidth: 150,
            align: "center",
            option: this.$select({
              key: "vip",
              option: {
                option: {
                  label: "vipNo",
                  value: "vipNo",
                  model: "vipNo",
                  change: (propValue, row) => {
                    if (row && this.options.tableIndex != undefined) {
                      const isRepetition = this.options.list.some(
                        (item) => item.vipId == row.vipId
                      );
                      if (!isRepetition) {
                        this.options.columns[0].option.showItem = [row];
                        this.options.list[this.options.tableIndex] = row;
                      } else {
                        this.options.list[this.options.tableIndex] = {};
                        this.$message.error("请选择不同的会员");
                      }
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent("getSelectData", row);
                  },
                  buttons: [
                    {
                      type: "more",
                      option: {
                        title: "选择会员",
                        width: 1250,
                        type: "TreeAndTable",
                        formData: {
                          ...this.$dialog({ key: "vip" }),
                          table: {
                            ...this.$dialog({ key: "vip" }).table,
                            pagination: {
                              pageNum: 1,
                              pageSize: 15,
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              },
            }).option,
            disabled: false,
            rules: true,
          },
          {
            prop: "vipName",
            label: "会员名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "tel",
            label: "手机号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "registerDate",
            label: "注册时间",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "cardStatusName",
            label: "会员卡状态",
            minWidth: 120,
            align: "center",
          },
          {
            type: "localSelect",
            prop: "cardNewStatus",
            label: "会员卡新状态",
            minWidth: 120,
            align: "center",
            disabled: false,
            rules: true,
            option: {
              remote: this.getDicts,
              remoteBody: "vip_card_status",
              label: "dictLabel",
              value: "dictValue",
              filterable: true,
            },
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 120,
            align: "center",
            type: "input",
            disabled: false,
            notVerify: true,
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50, //分页高度
      rules: {
        billNo: [
          {
            required: true,
            message: "请输入单据编号",
            trigger: ["blur", "change"],
          },
        ],
        billDate: [{ required: true, message: "请输入单据日期", trigger: "blur" }],
      },
    };
  },
  activated() {
    this.handleResize();
    this.getDetail();
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.getDetail();
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  },
  methods: {
    //获取详情
    async getDetail() {
      this.reset();
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        try {
          //获取方案详情
          const { data } = await stateChangeGetDetail(this.$route.query.billId);
          this.options.columns[0].option.showItem = data.billDetailItems;

          //方案赋值
          this.options = {
            ...this.options,
            ...data,
            list: data.billDetailItems,
          };
        } catch (err) {}
        this.loading = false;
      } else {
        this.getBillno();
      }
    },
    //自适应获取高度
    async handleResize() {
      setTimeout(() => {
        this.pageH = document.getElementById("wrap").offsetHeight;
        this.btnsH = document.getElementById("topOperatingButton").offsetHeight;
        this.basicH = document.getElementById("basicInformation").offsetHeight;
        this.options.tableHeight =
          this.pageH - this.btnsH - this.basicH - this.paginationH - 68;
      }, 10);
    },
    async handleEvent(type, row) {
      switch (type) {
        case "clickVip":
          if (["2", "3"].includes(this.options.billStatus)) return;
          //弹窗配置
          this.dialogOptions = {
            index: row.index,
            title: "选择会员",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vip" }),
          };
          this.dialogOptions.formData.table.pagination.cardStatus = undefined;
          break;
        case "getSelectData":
          if (!row) return;
          let { check } = row;
          if (check.length <= 0) return;
          this.options.columns[0].option.showItem = check;

          let tableIndex = this.options.tableIndex;
          this.options.tableIndex = undefined;
          this.options.list.splice(tableIndex, 1, ...check);
          this.options.list = uniqWith(this.options.list, (x, y) => x.vipId == y.vipId);
          break;
        case "openBatchChangeStatus":
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          //弹窗配置
          this.dialogOptions = {
            title: "批量修改状态",
            width: 500,
            show: true,
            type: "VipBatchUpdate",
            formData: {
              type: "localSelect",
              label: "会员卡状态",
              value: undefined,
              click: "getBatchStatus",
              option: {
                remote: this.getDicts,
                remoteBody: "vip_card_status",
                label: "dictLabel",
                value: "dictValue",
                filterable: true,
              },
            },
          };
          break;
        case "getBatchStatus":
          if (!this.dialogOptions.formData.value) return;
          this.options.columns[7].option.showItem = this.options.columns[7].option.data;
          this.options.check = this.options.check.map((item) => {
            if (item.vipId)
              this.$set(item, "cardNewStatus", this.dialogOptions.formData.value);
            return item;
          });
          break;
        case "batchDel": //批量删除
          if (["2", "3"].includes(this.options.billStatus)) {
            return this.$message.warning("请操作未审核的方案");
          }
          if (!this.options.check.length) {
            return this.$message.warning("请选择要操作的数据项");
          }
          this.$confirm("确定删除选中的数据?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              let vipIds = this.options.check.map((item) => item.vipId);
              this.options.list = this.options.list.filter(
                (item) => !vipIds.includes(item.vipId)
              );
              if (this.options.list.length <= 0) this.options.list = [{}];
            })
            .catch(() => {});
          break;
        case "tableIndex":
          this.options.tableIndex = row;
          break;
        default:
          break;
      }
    },
    // 保存
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let form = {
            billNo: this.options.billNo, // 单据号
            billId: this.options.billId, // 单据ID
            billDate: this.options.billDate, // 单据时间
            billDetailItems: this.options.list,
          };
          form.billDetailItems = form.billDetailItems.filter((item) => item.vipId);
          if (form.billId) {
            try {
              const { data } = await stateChangeUpdate(form);
              this.$message.success("修改成功");
              this.$set(this.options, "billStatus", data.billStatus);
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          } else {
            try {
              const { data } = await stateChangeSave(form);
              this.$message.success("新增成功");
              this.$set(this.options, "billStatus", data.billStatus);
              this.$set(this.options, "billId", data.billId);
              //新增
              if (isAdd) {
                this.reset();
                this.getBillno();
              }
            } catch (err) {}
          }
        } else this.$message.error("请把带有红星的必填项输入完整!");
      });
    },
    // 获取订单编号
    async getBillno() {
      this.options.billNo = await getBillNo(150206);
      this.options.billDate = getFormattedDate(2, "-");
    },
    // 单据状态审核
    async auditForm() {
      if (this.options.billStatus == "0") {
        try {
          const { data } = await stateChangeUpdateBillStatus({
            billIds: [this.options.billId],
            billStatus: 2,
          });
          this.$set(this.options, "billStatus", data.billStatus);
          this.$message.success(`审核成功`);
        } catch (err) {}
      } else {
        this.$message.warning("请操作未审核的方案");
      }
    },
    //初始化数据
    reset() {
      this.loading = false; //遮罩层
      //表格配置
      this.options = {
        ...this.options,
        billDate: "", // 单据日期（当前日期）
        billNo: undefined, // 单据编号
        billId: undefined, //单据id
        billStatus: undefined, //单据状态
        mutiSelect: true, //是否多选
        status: undefined, //状态
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: 0, //表格高度
        tableIndex: undefined,
      };
      //弹窗配置
      this.dialogOptions = {
        title: "选择会员",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      };
      this.pageH = 0; //页面高度
      this.btnsH = 0; //搜索框高度
      this.basicH = 0; //基础框高度
      this.paginationH = 50; //分页高度
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.table {
  padding: 10px;
}
</style>
