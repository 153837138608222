<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="form" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :isStopBtn="true"
        id="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditBill"
        @getQuit="$router.go(-1)"
        @isStop="getEnable"
        :showAudit="form.billStatus != '0'"
        :showIsStop="form.billStatus != '2'"
      >
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="form.billStatus"
      >
        <template slot="cardContent">
          <div class="baseInfoBody">
            <el-form-item prop="billNo" label="方案编号" label-width="80px">
              <el-input
                class="baseInput"
                :disabled="true"
                v-model="form.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="billName" label="方案名称" label-width="80px">
              <el-input
                class="baseInput"
                :disabled="disabled"
                v-model="form.billName"
                placeholder="方案名称"
              ></el-input>
            </el-form-item>
            <el-form-item prop="datetime" label="有效日期" label-width="80px">
              <el-date-picker
                v-model="form.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :disabled="disabled"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案备注" label-width="80px">
              <el-input
                class="remarkinput"
                :disabled="disabled"
                v-model="form.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="积分兑换储值金规则">
        <template slot="cardContent">
          <div class="x-x">
            <el-form-item prop="eachScore" label-width="1px">
              <el-input
                :disabled="disabled"
                v-model="form.eachScore"
                size="mini"
                class="inputWidth marR10"
              ></el-input>
              <span>积分兑换</span>
            </el-form-item>
            <el-form-item prop="eachMoney" label-width="10px">
              <el-input
                :disabled="disabled"
                v-model="form.eachMoney"
                size="mini"
                class="inputWidth"
              ></el-input>
              <span class="marL10">元,</span>
              <span>每次兑换必须是积分</span>
            </el-form-item>

            <el-form-item prop="eachMultiple" label-width="10px">
              <el-input
                :disabled="disabled"
                v-model="form.eachMultiple"
                size="mini"
                class="inputWidth marR10"
              ></el-input>
              <span>的倍数</span>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用门店">
        <template slot="cardContent">
          <div class="rangeShop">
            <el-form-item label="适用门店" label-width="70px">
              <el-radio-group v-model="form.shopRangeType" :disabled="disabled">
                <el-radio
                  v-for="dict in dict.type.fill_shop_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>

            <div v-show="form.shopRangeType == '1' || form.shopRangeType == '2'">
              <el-button
                @click="handleEvent('openShopDialog', { curTable: 'shopOptions' })"
                :disabled="disabled"
                class="marB10"
                >指定门店资料</el-button
              >
              <!-- 可编辑表格 -->
              <EditTable :options="shopOptions" />
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用会员">
        <template slot="cardContent">
          <div class="rangeVip">
            <el-form-item label="适用会员" label-width="70px">
              <el-radio-group v-model="form.vipLevelRangeType" :disabled="disabled">
                <el-radio
                  v-for="dict in dict.type.fill_vip_level_range_type"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <div v-show="form.vipLevelRangeType == '1' || form.vipLevelRangeType == '2'">
              <el-button
                @click="
                  handleEvent('openVipLevelDialog', {
                    curTable: 'vipLevelOptions',
                  })
                "
                :disabled="disabled"
                class="marT10 marB10"
                >指定会员等级</el-button
              >

              <!-- 可编辑表格 -->
              <EditTable :options="vipLevelOptions" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import { uniqWith } from "lodash"; //去重
import vipLeve from "@/views/components/vipLeve"; //会员等级
import shopInfo from "@/views/components/shopInfo"; //门店资料
import {
  updateOrderStatus,
  getOrderDetail,
  addOrder,
  updateOrder,
  updateEnable,
} from "@/api/vip/base/rebateRule"; //会员规则
import { getBillNo } from "@/api/codeRule"; //会员充值规则号

export default {
  name: "rebateRuleDetail",
  dicts: ["fill_vip_level_range_type", "fill_shop_range_type"],
  components: {
    vipLeve,
    shopInfo,
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
  },
  data() {
    return {
      //门店表格配置
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "shopNo",
            label: "门店编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopTypeName",
            label: "门店类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //会员级别表格配置
      vipLevelOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/会员)
        title: "选择门店",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      // 禁止选择过去日期（可以选择今天）
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      vipLevelFrame: "", //会员级别导入哪个表格的判断依据
      Enable: true, //启用或禁用会员充值规则
      showAudit: true, //审核
      disabled: false, //禁用全部按钮
      //展示图标
      iconType: "",
      OpenShopInfo: false, //选择门店信息开关
      OpenVipLeve: false, //选择会员等级开关
      loadingShopInfo: false, //门店资料适用范围表格加载
      loadingVip: false, //会员适用范围表格加载
      loading: false, //加载
      isIconInfo: false, //是否显示基本信息
      isIconRange: false, //是否显示适用范围
      isIconSuitGoods: false, //是否显示充值规则
      isIconManyIntegration: false, //是否显示多倍积分
      isIconDiscount: false, //是否显示会员级别折扣
      form: {
        datetime: [], //开始时间到结束时间
        begTime: undefined, //开始时间
        endTime: undefined, //结束时间
        billName: undefined, //单据名称
        eachMoney: undefined, //抵现金额
        eachMultiple: undefined, //积分抵现的倍数
        eachScore: undefined, //抵现积分
        shopRangeType: "0", //适用门店
        vipLevelRangeType: "0", //适用会员
        vipScoreOffsetRuleShopReqs: [], //适用门店数组
        vipScoreOffsetRuleVipLevelReqs: [], //适用会员数组
      },
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则添加对应编码格式",
            trigger: ["blur", "change"],
          },
        ],
        billName: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: ["blur", "change"],
          },
        ],
        eachMoney: [
          {
            required: true,
            message: "请输入抵现金额",
            trigger: ["blur", "change"],
          },
        ],
        eachScore: [
          {
            required: true,
            message: "请输入抵现积分",
            trigger: ["blur", "change"],
          },
        ],
        eachMultiple: [
          {
            required: true,
            message: "请输入积分抵现的倍数",
            trigger: ["blur", "change"],
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择有效日期",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  watch: {
    "form.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.shopOptions.status = disabledColumns;
        this.vipLevelOptions.status = disabledColumns;
        this.disabled = disabledColumns;
      },
      immediate: true,
    },
  },
  activated() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //获取详情
    async getDetail() {
      this.reset();
      if (this.$route.query.type === "Update") {
        if (this.$route.query.billId) {
          this.loading = true;
          //获取会员积分规则详情
          const res1 = await getOrderDetail(
            this.$route.query.billId,
            "/api/system/rule/getDetail"
          );
          //会员充值规则赋值
          this.form = res1.data;
          this.form.datetime = [];
          this.form.datetime = [this.form.begTime, this.form.endTime];
          this.shopOptions.list = this.form.vipScoreOffsetRuleShopReqs;
          this.vipLevelOptions.list = this.form.vipScoreOffsetRuleVipLevelReqs;
          this.loading = false;
        }
      } else {
        this.disabled = false;
        let billNo = await getBillNo(150104);
        this.$set(this.form, "billNo", billNo);
      }
    },
    async handleEvent(type, row) {
      switch (type) {
        case "openShopDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: row?.curTable, //当前类型(门店/会员)
            title: "选择门店",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "shop" }),
          };
          break;
        case "openVipLevelDialog":
          //弹窗配置
          this.dialogOptions = {
            curTable: row?.curTable, //当前类型(门店/会员)
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "dialogChange": //获取弹窗数据
          let list = [];
          let curTable = this.dialogOptions.curTable;
          //门店/会员
          if (this.dialogOptions.formData.table.check.length <= 0) return;
          list = this.dialogOptions.formData.table.check;
          list = [...list, ...this[curTable].list];
          list = uniqWith(list, (x, y) =>
            curTable == "shopOptions"
              ? x.shopId == y.shopId
              : x.vipLevelId == y.vipLevelId
          );
          this[curTable].list = list;
          break;
        default:
          break;
      }
    },
    //启用停用
    async getEnable(value) {
      await updateEnable([this.form.billId], "/api/system/rule/isStop");
      if (value === "启用") {
        this.$message({
          message: `启用成功`,
          type: "success",
        });
      } else {
        this.$message({
          message: `禁用成功`,
          type: "success",
        });
      }
    },

    //审核会员充值规则方法
    async auditBill() {
      if (["2", "3"].includes(this.form.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      try {
        //发送审核api
        const res = await updateOrderStatus(
          [this.form.billId],
          "/api/system/rule/updateBillStatus"
        );
        this.$set(this.form, "billStatus", res.data.billStatus);
        // 弹出提示
        this.$message.success("审核成功");
      } catch {}
    },
    /** 提交按钮 */
    async submitForm(isAdd) {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          this.form.vipScoreOffsetRuleShopReqs = this.shopOptions.list;
          this.form.vipScoreOffsetRuleVipLevelReqs = this.vipLevelOptions.list;
          this.form.begTime = this.form.datetime[0];
          this.form.endTime = this.form.datetime[1];
          //判断适用门店
          if (
            this.form.shopRangeType != "0" &&
            this.form.vipScoreOffsetRuleShopReqs.length <= 0
          ) {
            return this.$message.error("请选择门店");
          }
          //判断适用会员
          if (
            this.form.vipLevelRangeType != "0" &&
            this.form.vipScoreOffsetRuleVipLevelReqs.length <= 0
          ) {
            return this.$message.error("请选择会员");
          }
          this.loading = true;
          try {
            if (this.form.billId) {
              const res = await updateOrder(this.form, "/api/system/rule/update");
              this.$modal.msgSuccess("修改成功");
              //重新赋值给表单，可以把id赋值
              if (isAdd) {
                await this.reset();
                let billNo = await getBillNo(150104);
                this.$set(this.form, "billNo", billNo);
              } else {
                this.form = res.data;
                this.$set(this.form, "datetime", [this.form.begTime, this.form.endTime]);
                this.shopOptions.list = this.form.vipScoreOffsetRuleShopReqs;
                this.vipLevelOptions.list = this.form.vipScoreOffsetRuleVipLevelReqs;
              }
            } else {
              const res = await addOrder(this.form, "/api/system/rule/save");
              this.$modal.msgSuccess("新增成功");
              //重新赋值给表单，可以把id赋值
              if (isAdd) {
                await this.reset();
                let billNo = await getBillNo(150104);
                this.$set(this.form, "billNo", billNo);
              } else {
                this.form = res.data;
                this.$set(this.form, "datetime", [this.form.begTime, this.form.endTime]);
                this.shopOptions.list = this.form.vipScoreOffsetRuleShopReqs;
                this.vipLevelOptions.list = this.form.vipScoreOffsetRuleVipLevelReqs;
              }
            }
            this.loading = false;
          } catch (error) {}
        }
      });
    },
    // 页面重置
    reset() {
      //门店表格配置
      this.shopOptions = {
        ...this.shopOptions,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
      };
      //会员级别表格配置
      (this.vipLevelOptions = {
        ...this.vipLevelOptions,
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
      }),
        //弹窗配置
        (this.dialogOptions = {
          curType: "", //当前类型(门店/会员)
          title: "选择门店",
          width: 1250,
          show: false,
          type: "TreeAndTable",
        }),
        (this.vipLevelFrame = ""), //会员级别导入哪个表格的判断依据
        (this.Enable = true), //启用或禁用会员充值规则
        (this.showAudit = true), //审核
        (this.disabled = false), //禁用全部按钮
        //展示图标
        (this.iconType = ""),
        (this.OpenShopInfo = false), //选择门店信息开关
        (this.OpenVipLeve = false), //选择会员等级开关
        (this.loadingShopInfo = false), //门店资料适用范围表格加载
        (this.loadingVip = false), //会员适用范围表格加载
        (this.loading = false), //加载
        (this.isIconInfo = false), //是否显示基本信息
        (this.isIconRange = false), //是否显示适用范围
        (this.isIconSuitGoods = false), //是否显示充值规则
        (this.isIconManyIntegration = false), //是否显示多倍积分
        (this.isIconDiscount = false), //是否显示会员级别折扣
        (this.form = {
          datetime: [], //开始时间到结束时间
          begTime: undefined, //开始时间
          endTime: undefined, //结束时间
          billName: undefined, //单据名称
          eachMoney: undefined, //抵现金额
          eachMultiple: undefined, //积分抵现的倍数
          eachScore: undefined, //抵现积分
          shopRangeType: "0", //适用门店
          vipLevelRangeType: "0", //适用会员
          vipScoreOffsetRuleShopReqs: [], //适用门店数组
          vipScoreOffsetRuleVipLevelReqs: [], //适用会员数组
        });
      this.resetForm("form");
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
}
::v-deep .cardContent {
  padding: 20px 20px 10px;
}
//基本信息

.baseInfoBody {
  display: flex;

  .baseInput {
    width: 200px;
  }

  .remarkinput {
    width: 300px;
  }
}

//适用门店
.rangeShop,
.rangeVip {
  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}

//多选框
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}

//输入框
::v-deep .el-input__inner {
  height: 30px;
}

//选择商品
::v-deep .el-button--medium {
  padding: 6px 20px;
}
</style>
