<template>
  <div class="wrap" v-loading="loading">
    <el-form ref="form" :model="scoreRuleForm" :rules="rules" label-width="96px">
      <topOperatingButton
        :isExamineBtn="true"
        :showAudit="false"
        :isStopBtn="true"
        id="topOperatingButton"
        @submitForm="submitForm(false)"
        @addBill="submitForm(true)"
        @auditBill="auditBill"
        @getQuit="$router.go(-1)"
        @isStop="getEnable"
      >
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="scoreRuleForm.billStatus"
      >
        <template slot="cardContent">
          <div class="baseInfoBody">
            <el-form-item prop="billNo" label="方案编号" label-width="80px">
              <el-input
                class="baseInput"
                :disabled="true"
                v-model="scoreRuleForm.billNo"
                placeholder="方案编号"
              ></el-input>
            </el-form-item>
            <el-form-item prop="billRuleName" label="方案名称" label-width="80px">
              <el-input
                class="baseInput"
                :disabled="disabled"
                v-model="scoreRuleForm.billRuleName"
                placeholder="方案名称(1-40字符)"
                maxlength="40"
              ></el-input>
            </el-form-item>
            <el-form-item prop="datetime" label="有效日期" label-width="80px">
              <el-date-picker
                v-model="scoreRuleForm.datetime"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
                :disabled="disabled"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="方案备注" label-width="80px">
              <el-input
                class="remarkinput"
                :disabled="disabled"
                v-model="scoreRuleForm.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
              />
            </el-form-item>
          </div>
          <el-form-item label-width="0px">
            <!-- <el-checkbox
              v-model="scoreRuleForm.isDeductNoScore"
              :disabled="disabled"
              >积分抵现整单不积分</el-checkbox
            > -->
            <!-- <el-checkbox
              v-model="scoreRuleForm.isLargessNoScore"
              :disabled="disabled"
              >储值赠送金额不积分</el-checkbox
            > -->

            <el-checkbox v-model="scoreRuleForm.isPromoNoScore" :disabled="disabled"
              >折扣特价促销商品不积分</el-checkbox
            >
            <el-checkbox v-model="scoreRuleForm.isUsableNoScore" :disabled="disabled"
              >储值可用金额不积分</el-checkbox
            >
          </el-form-item>
          <div class="marT20 x-f">
            <el-form-item
              label="每消费(订单实付金额)"
              label-width="160px"
              prop="eachMoney"
            >
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="scoreRuleForm.eachMoney"
                placeholder="订单实付金额"
                :max="9999999"
                @input="
                  limitInputlength(
                    scoreRuleForm.eachMoney,
                    scoreRuleForm,
                    'eachMoney',
                    true
                  )
                "
              />
              <span> 元积 </span>
            </el-form-item>
            <el-form-item label-width="5px" prop="eachScore">
              <el-input
                class="inputWidth"
                :disabled="disabled"
                v-model="scoreRuleForm.eachScore"
                placeholder="得积分"
                :max="9999999"
                @input="
                  limitInputlength(
                    scoreRuleForm.eachScore,
                    scoreRuleForm,
                    'eachScore',
                    true
                  )
                "
              />
              <span> 分（积分默认整数）</span>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用商品">
        <template slot="cardContent">
          <div>
            <span class="fontS14 marR15">适用商品</span>
            <el-radio-group v-model="scoreRuleForm.goodsRangeType" :disabled="disabled">
              <el-radio
                v-for="dict in dict.type.vip_goods_range_type"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
            <div
              v-show="
                scoreRuleForm.goodsRangeType == '2' || scoreRuleForm.goodsRangeType == '1'
              "
            >
              <div class="marT15 marB15">
                <el-button
                  @click="handleEvent('openGoodsDialog', { curTable: 'goodsOptions' })"
                  :disabled="disabled"
                  >指定商品</el-button
                >

                <el-button
                  @click="handleEvent('openBrandDialog', { curTable: 'goodsOptions' })"
                  :disabled="disabled"
                  >指定品牌</el-button
                >

                <el-button
                  @click="
                    handleEvent('openCategoryDialog', {
                      curTable: 'goodsOptions',
                    })
                  "
                  :disabled="disabled"
                  >指定类别</el-button
                >
              </div>
              <!-- 可编辑表格 -->
              <EditTable :options="goodsOptions" />
            </div>
          </div>
          <div class="marT15">
            <span class="fontS14 marR15">例外商品</span>
            <el-tooltip placement="top">
              <div slot="content">
                如果按全部商品、或按类别、品牌、标签设置积分时,<br />可以剔除部分例外商品不积分或设置特殊的积分
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
          </div>
          <div>
            <div class="marT15 marB15">
              <el-button
                @click="
                  handleEvent('openGoodsDialog', {
                    curTable: 'exceptionGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定商品</el-button
              >

              <el-button
                @click="
                  handleEvent('openBrandDialog', {
                    curTable: 'exceptionGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定品牌</el-button
              >

              <el-button
                @click="
                  handleEvent('openCategoryDialog', {
                    curTable: 'exceptionGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定类别</el-button
              >
            </div>
            <el-form :model="exceptionGoodsOptions" ref="exceptionGoodsOptions">
              <!-- 可编辑表格 -->
              <EditTable :options="exceptionGoodsOptions" />
            </el-form>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="多倍积分">
        <template slot="cardContent">
          <div class="marB15">
            <span class="marR15 fontS14">取值规则</span>
            <el-tooltip placement="top">
              <div slot="content">
                多倍积分互斥：取会员级别、特殊日期、会员生日,
                <br />三者的最大值； <br />多倍积分叠加：取会员级别、特殊日期、会员生日
                <br />任意两者及以上之和。
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
            <el-radio-group
              v-model="scoreRuleForm.multScoreMode"
              class="marL15"
              :disabled="disabled"
            >
              <el-radio
                v-for="dict in dict.type.vip_mult_score_mode"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <div>
            <el-checkbox
              v-model="scoreRuleForm.isMultVipLevelScore"
              class="marB15"
              :disabled="disabled"
            >
              会员级别多倍积分</el-checkbox
            >
            <div v-show="scoreRuleForm.isMultVipLevelScore">
              <el-button
                @click="
                  handleEvent('openVipLevelDialog', {
                    curTable: 'multiVipLevelOptions',
                  })
                "
                :disabled="disabled"
                class="marB15"
                >指定会员等级</el-button
              >
              <el-form :model="multiVipLevelOptions" ref="multiVipLevelOptions">
                <!-- 可编辑表格 -->
                <EditTable :options="multiVipLevelOptions" />
              </el-form>
            </div>
          </div>
          <div>
            <el-checkbox
              v-model="scoreRuleForm.isMultSpecialDateScore"
              class="marB15"
              :disabled="disabled"
              >会员特殊日期多倍积分</el-checkbox
            >
            <div v-if="scoreRuleForm.isMultSpecialDateScore">
              <div class="x-f">
                <el-button
                  @click="
                    handleEvent('openTimeDateDialog', {
                      curTable: 'timeDateOptions',
                    })
                  "
                  :disabled="disabled"
                  class="marB15"
                  >选择日期</el-button
                >
                <span class="fontSize14 marR10 marL10 marB15">积分倍数</span>
                <el-form-item label-width="0px" prop="specialDateScoreMult">
                  <el-input
                    class="inputWidth"
                    v-model="scoreRuleForm.specialDateScoreMult"
                    :disabled="disabled"
                  />
                </el-form-item>
              </div>
              <!-- 可编辑表格 -->
              <EditTable :options="timeDateOptions" />
            </div>
          </div>
          <div>
            <el-checkbox v-model="scoreRuleForm.isMultBirthdayScore" :disabled="disabled">
              会员生日多倍积分</el-checkbox
            >
            <div v-if="scoreRuleForm.isMultBirthdayScore" class="x-f">
              <el-radio-group
                v-model="scoreRuleForm.birthdayScoreMode"
                :disabled="disabled"
              >
                <el-radio
                  v-for="dict in dict.type.birthday_score_mode"
                  :key="dict.value"
                  :label="dict.value"
                  >{{ dict.label }}</el-radio
                >
              </el-radio-group>
              <span class="fontS14 marL15">积分倍数</span>
              <el-form-item label-width="0px" prop="birthdayScoreMult">
                <el-input
                  class="inputWidth marL15"
                  :disabled="disabled"
                  v-model="scoreRuleForm.birthdayScoreMult"
                  placeholder="积分倍数"
                ></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="marT15">
            <span class="fontS14 marR15">例外商品</span>
            <el-tooltip placement="top">
              <div slot="content">
                如果按全部商品、或按类别、品牌、标签设置积分时,<br />可以剔除部分例外商品不积分或设置特殊的积分
              </div>
              <i class="el-icon-question" />
            </el-tooltip>
          </div>
          <div>
            <div class="marT15 marB15">
              <el-button
                @click="
                  handleEvent('openGoodsDialog', {
                    curTable: 'multiExcepGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定商品</el-button
              >

              <el-button
                @click="
                  handleEvent('openBrandDialog', {
                    curTable: 'multiExcepGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定品牌</el-button
              >

              <el-button
                @click="
                  handleEvent('openCategoryDialog', {
                    curTable: 'multiExcepGoodsOptions',
                  })
                "
                :disabled="disabled"
                >指定类别</el-button
              >
            </div>
            <el-form :model="multiExcepGoodsOptions" ref="multiExcepGoodsOptions">
              <!-- 可编辑表格 -->
              <EditTable :options="multiExcepGoodsOptions" />
            </el-form>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用渠道">
        <template slot="cardContent">
          <el-form-item label="适用渠道" label-width="80px" prop="AllowOfflineShop">
            <el-checkbox-group v-model="scoreRuleForm.AllowOfflineShop">
              <el-checkbox label="0" :disabled="disabled">线下门店 </el-checkbox>
              <el-checkbox label="1" :disabled="disabled">线上商城 </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用门店">
        <template slot="cardContent">
          <el-form-item label="适用门店" label-width="80px">
            <el-radio-group v-model="scoreRuleForm.shopRangeType" :disabled="disabled">
              <el-radio
                v-for="dict in dict.type.fill_shop_range_type"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
            <div
              v-show="
                scoreRuleForm.shopRangeType == '1' || scoreRuleForm.shopRangeType == '2'
              "
            >
              <el-button
                @click="handleEvent('openShopDialog', { curTable: 'shopOptions' })"
                :disabled="disabled"
                class="marT15 marB15"
                >指定门店资料</el-button
              >

              <!-- 可编辑表格 -->
              <EditTable :options="shopOptions" />
            </div>
          </el-form-item>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="适用会员">
        <template slot="cardContent">
          <el-form-item label="适用会员" label-width="80px">
            <el-radio-group
              v-model="scoreRuleForm.vipLevelRangeType"
              :disabled="disabled"
            >
              <el-radio
                v-for="dict in dict.type.fill_vip_level_range_type"
                :key="dict.value"
                :label="dict.value"
                >{{ dict.label }}</el-radio
              >
            </el-radio-group>
            <div
              v-show="
                scoreRuleForm.vipLevelRangeType == '1' ||
                scoreRuleForm.vipLevelRangeType == '2'
              "
            >
              <el-button
                @click="
                  handleEvent('openVipLevelDialog', {
                    curTable: 'vipLevelOptions',
                  })
                "
                :disabled="disabled"
                class="marT15 marB15"
                >指定会员级别</el-button
              >
              <!-- 可编辑表格 -->
              <EditTable :options="vipLevelOptions" />
            </div>
          </el-form-item>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog from "@/components/Dialog";
import topOperatingButton from "@/views/components/topOperatingButton"; //顶部按钮
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import EditTable from "@/components/editTable"; //可编辑表格
import { uniqWith } from "lodash"; //去重

import {
  updateOrderStatus,
  getOrderDetail,
  addOrder,
  updateOrder,
  updateEnable,
} from "@/api/vip/base/fillRule"; //会员规则
import { getBillNo } from "@/api/codeRule"; //会员充值规则号
import { getDateChangeDetail } from "@/utils/public"; //适用时间转换
import { limitInputlength } from "@/utils";
export default {
  name: "scoreRuleDetail",
  dicts: [
    "fill_vip_level_range_type",
    "fill_shop_range_type",
    "vip_discount_priority",
    "vip_goods_range_type",
    "vip_applicable_commodities_appoint_type",
    "vip_mult_score_mode",
    "birthday_score_mode",
  ],
  components: {
    topOperatingButton,
    cardTitleCom,
    EditTable,
    Dialog,
  },
  watch: {
    "scoreRuleForm.billStatus": {
      handler(val) {
        const disabledColumns = ["2", "3"].includes(val);
        this.goodsOptions.status = val;
        this.exceptionGoodsOptions.columns[5].disabled = disabledColumns;
        this.exceptionGoodsOptions.columns[6].disabled = disabledColumns;
        this.exceptionGoodsOptions.status = val;
        this.multiExcepGoodsOptions.columns[5].disabled = disabledColumns;
        this.multiExcepGoodsOptions.status = val;
        this.shopOptions.status = val;
        this.vipLevelOptions.status = val;
        this.timeDateOptions.status = val;
        this.multiVipLevelOptions.status = val;
        this.multiVipLevelOptions.columns[2].disabled = disabledColumns;
        this.disabled = disabledColumns;
      },
      immediate: true,
    },
  },
  data() {
    return {
      scoreRuleForm: {
        AllowOfflineShop: ["0", "1"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        billStatus: "",
        billNo: undefined,
        billStatus: undefined,
        datetime: "", //有效日期
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        birthdayScoreMode: "0", //会员生日多倍模式
        multScoreMode: "0", //多倍积分取值规则(0:多倍积分互斥1:多倍积分叠加)
        goodsItem: [], //会员积分规则积分适用商品的请求对象
        exclGoodsItem: [], //会员积分规则积分例外商品的请求对象
        multExclGoodsItem: [], //会员积分规则多倍积分例外商品的请求对象
        multVipLevelItem: [], //会员积分规则多倍积分会员等级的请求对象
        vipLevelItem: [], //会员积分规则适用会员级别的请求对象
        shopItem: [], //适用门店
        multSpecialDateItem: [], //特殊日期
        isDeductNoScore: false,
        isLargessNoScore: false,
        isPromoNoScore: false,
        isUsableNoScore: false,
        isMultVipLevelScore: false,
        isMultSpecialDateScore: false,
        isMultBirthdayScore: false,
      },
      //商品表格配置
      goodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //例外商品表格配置
      exceptionGoodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "eachMoney",
            label: "实付金额",
            minWidth: 120,
            align: "center",
            type: "input",
            disabled: false,
            rules: true,
          },
          {
            prop: "eachScore",
            label: "积分",
            minWidth: 120,
            type: "input",
            align: "center",
            disabled: false,
            rules: true,
          },
        ],
      },
      //多倍例外商品表格配置
      multiExcepGoodsOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "lineType",
            label: "类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineNo",
            label: "编号",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "lineName",
            label: "名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "goodsSpec",
            label: "规格",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "unitName",
            label: "单位",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "scoreMult",
            label: "积分倍数",
            minWidth: 120,
            align: "center",
            disabled: false,
            rules: true,
            type: "input",
          },
        ],
      },
      //门店表格配置
      shopOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "shopNo",
            label: "门店编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopName",
            label: "门店名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "shopTypeName",
            label: "门店类型",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //会员级别表格配置
      vipLevelOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //多倍会员级别表格配置
      multiVipLevelOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编码",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "scoreMult",
            label: "积分倍数",
            minWidth: 120,
            align: "center",
            disabled: false,
            rules: true,
            type: "input",
          },
          {
            prop: "",
            label: "",
            minWidth: 150,
            align: "center",
          },
        ],
      },
      //时间表格配置
      timeDateOptions: {
        curListIndex: -1, //当前表格行
        mutiSelect: false, //是否多选
        list: [],
        echoList: [],
        curListIndex: -1, //当前表格下标
        tableCellLabel: "序号",
        tableHeight: undefined, //表格高度
        status: undefined, //单据状态
        isNoAddBtn: true, //不显示表格增加
        hideTotal: true,
        columns: [
          {
            prop: "dateTime",
            label: "时间范围",
            minWidth: 120,
            align: "center",
          },
          {
            prop: "",
            label: "",
            minWidth: 200,
            align: "center",
          },
        ],
      },
      //弹窗配置
      dialogOptions: {
        curType: "", //当前类型(门店/会员/商品/类别/品牌)
        title: "选择商品",
        width: 1250,
        show: false,
        type: "TreeAndTable",
      },
      // 禁止选择过去日期（可以选择今天）
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      //当前赋值表格
      curTable: "",
      disabled: false, //禁用全部按钮
      billType: undefined, //会员充值规则类型
      loading: false, //加载
      //获取单据详情url
      urlDetail: undefined,
      //修改单据url
      urlUpdate: undefined,
      //新增单据url
      urlAdd: undefined,
      //更新单据状态url
      urlBillStatus: undefined,
      //启用停用
      urlEnable: undefined,
      rules: {
        billNo: [
          {
            required: true,
            message: "请到编码规则添加对应编码格式",
            trigger: ["blur", "change"],
          },
        ],
        billRuleName: [
          {
            required: true,
            message: "请输入方案名称",
            trigger: ["blur", "change"],
          },
        ],
        datetime: [
          {
            required: true,
            message: "请选择有效日期",
            trigger: ["blur", "change"],
          },
        ],
        eachMoney: [
          {
            required: true,
            message: "请输入订单实付金额",
            trigger: ["blur", "change"],
          },
        ],
        eachScore: [
          {
            required: true,
            message: "请输入积分",
            trigger: ["blur", "change"],
          },
        ],
        specialDateScoreMult: [
          {
            required: true,
            message: "请输入日期积分倍数",
            trigger: ["blur", "change"],
          },
        ],
        birthdayScoreMult: [
          {
            required: true,
            message: "请输入生日积分倍数",
            trigger: ["blur", "change"],
          },
        ],
        AllowOfflineShop: [
          {
            required: true,
            message: "请选择适用渠道",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  activated() {
    this.getDetail();
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    //保留小数位
    limitInputlength,
    //获取详情
    async getDetail() {
      this.reset();
      this.billType = 150103;
      this.changeBillType(this.billType);
      if (this.$route.query.type === "Update" && this.$route.query.billId) {
        this.loading = true;
        //获取会员积分规则详情
        const res1 = await getOrderDetail(this.$route.query.billId, this.urlDetail);
        //会员充值规则赋值
        this.scoreRuleForm = {
          ...this.scoreRuleForm,
          ...res1.data,
        };
        this.scoreRuleForm.multSpecialDateItem = await getDateChangeDetail(
          this.scoreRuleForm.multSpecialDateItem
        );
        this.$set(this.scoreRuleForm.datetime, 0, this.scoreRuleForm.begTime);
        this.$set(this.scoreRuleForm.datetime, 1, this.scoreRuleForm.endTime);
        this.$set(this.scoreRuleForm, "AllowOfflineShop", []);
        if (this.scoreRuleForm.isAllowOfflineShop)
          this.scoreRuleForm.AllowOfflineShop.push("0");
        if (this.scoreRuleForm.isAllowOnlineShop)
          this.scoreRuleForm.AllowOfflineShop.push("1");
        this.$set(this.scoreRuleForm, "isAllowOfflineShop", false);
        this.$set(this.scoreRuleForm, "isAllowOnlineShop", false);
        this.goodsOptions.list = this.scoreRuleForm.goodsItem;
        this.exceptionGoodsOptions.list = this.scoreRuleForm.exclGoodsItem;
        this.multiExcepGoodsOptions.list = this.scoreRuleForm.multExclGoodsItem;
        this.multiVipLevelOptions.list = this.scoreRuleForm.multVipLevelItem;
        this.vipLevelOptions.list = this.scoreRuleForm.vipLevelItem;
        this.shopOptions.list = this.scoreRuleForm.shopItem;
        this.timeDateOptions.list = this.scoreRuleForm.multSpecialDateItem;
        this.loading = false;
      } else {
        this.disabled = false;
        this.getBillNoApi();
      }
    },
    async handleEvent(type, row) {
      if (row?.curTable) this.curTable = row.curTable; //标识当前表格
      switch (type) {
        case "openGoodsDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "goodsId", //当前类型(门店/商品/类别/品牌)
            title: "选择商品",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "goods" }),
          };
          break;
        case "openBrandDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "brandId", //当前类型(门店/商品/类别/品牌)
            title: "选择品牌",
            width: 120,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "brand" }),
          };
          break;
        case "openCategoryDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "categoryId", //当前类型(门店/商品/类别/品牌)
            title: "选择类别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "category" }),
          };
          break;
        case "openShopDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "shopId", //当前类型(门店/商品/类别/品牌)
            title: "选择门店",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "shop" }),
          };
          break;
        case "openVipLevelDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "vipLevelId", //当前类型(门店/商品/类别/品牌)
            title: "选择会员级别",
            width: 1250,
            show: true,
            type: "TreeAndTable",
            formData: this.$dialog({ key: "vipLevel" }),
          };
          break;
        case "openTimeDateDialog":
          //弹窗配置
          this.dialogOptions = {
            curType: "timeDate",
            title: "选择时间日期",
            width: 600,
            show: true,
            type: "TimeDateSelect",
            formData: {
              DateTimeList: this.timeDateOptions.echoList, // 日期
            },
          };
          //适用时间回显
          break;
        case "dialogChange": //获取弹窗数据
          let list = [];
          let dialogType = this.dialogOptions.curType;
          if (dialogType != "timeDate") {
            //会员级别/门店/会员/商品/类别/品牌
            if (this.dialogOptions.formData.table.check.length <= 0) return;
            list = this.dialogOptions.formData.table.check;
            list = [...list, ...this[this.curTable].list];

            if (["goodsId", "categoryId", "brandId"].includes(dialogType)) {
              list = this.getTableData(dialogType, list);
              list = uniqWith(list, (x, y) =>
                dialogType == "goodsId"
                  ? x[dialogType] == y[dialogType] && x.unitId == y.unitId
                  : x[dialogType] == y[dialogType]
              );
            } else {
              list = uniqWith(list, (x, y) => x[dialogType] == y[dialogType]);
            }
          } else {
            this[this.curTable].echoList = this.dialogOptions.formData.DateTimeList; //方便适用时间回显
            list = await getDateChangeDetail(this.dialogOptions.formData.DateTimeList);
          }
          this[this.curTable].list = list;
          break;
        default:
          break;
      }
    },
    //处理表格数据(商品/类别/品牌)
    getTableData(type, list) {
      let newList = [];
      switch (type) {
        case "goodsId":
          newList = list.flatMap((item) =>
            item.units.map((i) => ({
              ...item,
              ...i,
              lineType: 1,
              lineId: item.goodsId,
              lineName: item.goodsName,
              lineNo: item.goodsNo,
            }))
          );
          console.log(newList, "newList");
          break;
        case "categoryId":
          newList = list.map((item) => ({
            ...item,
            lineType: 3,
            lineId: item.categoryId,
            lineName: item.categoryName,
            lineNo: item.categoryNo,
          }));
          break;
        case "brandId":
          newList = list.map((item) => ({
            ...item,
            lineType: 2,
            lineId: item.brandId,
            lineName: item.brandName,
            lineNo: item.brandNo,
          }));
          break;
        default:
          break;
      }
      return newList;
    },
    //接口地址
    changeBillType(billType) {
      if (billType === 150103) {
        //会员积分规则
        this.urlAdd = "/api/system/vip/base/score/rule/save";
        this.urlBillStatus = "/api/system/vip/base/score/rule/updateStatus";
        this.urlDetail = "/api/system/vip/base/score/rule/getDetail";
        this.urlUpdate = "/api/system/vip/base/score/rule/update";
        this.urlEnable = "/api/system/vip/base/score/rule/isStop";
      }
    },
    //启用停用
    async getEnable(type) {
      const num = type == "启用" ? 0 : 1;
      try {
        await this.$confirm(`确定${type}该订单?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
        await updateEnable(
          {
            billIds: [this.scoreRuleForm.billId],
            isStop: num,
          },
          this.urlEnable
        );
        this.$message.success(`${type}成功`);
      } catch (error) {}
    },

    //审核会员充值规则方法
    async auditBill() {
      if (["2", "3"].includes(this.scoreRuleForm.billStatus)) {
        return this.$message.warning("请操作未审核的方案");
      }
      try {
        //发送审核api
        const res = await updateOrderStatus(
          {
            billIds: [this.scoreRuleForm.billId],
            billStatus: 2,
            billType: this.billType,
          },
          this.urlBillStatus
        );
        this.scoreRuleForm.billStatus = res.data.billStatus;
        // 弹出提示
        this.$message.success("审核成功");
      } catch (err) {}
    },
    /** 提交按钮 */
    async submitForm(isAdd) {
      try {
        await Promise.all([
          this.$refs["form"].validate(),
          this.$refs["exceptionGoodsOptions"].validate(),
          this.$refs["multiExcepGoodsOptions"].validate(),
          this.$refs["multiVipLevelOptions"].validate(),
        ]);
        this.scoreRuleForm = {
          ...this.scoreRuleForm,
          begTime: this.scoreRuleForm.datetime[0],
          endTime: this.scoreRuleForm.datetime[1],
          goodsItem: this.goodsOptions.list, //会员积分规则积分适用商品的请求对象
          exclGoodsItem: this.exceptionGoodsOptions.list, //会员积分规则积分例外商品的请求对象
          multExclGoodsItem: this.multiExcepGoodsOptions.list, //会员积分规则多倍积分例外商品的请求对象
          multVipLevelItem: this.multiVipLevelOptions.list, //会员积分规则多倍积分会员等级的请求对象
          vipLevelItem: this.vipLevelOptions.list, //会员积分规则适用会员级别的请求对象
          shopItem: this.shopOptions.list, //适用门店
          multSpecialDateItem: this.timeDateOptions.list, //特殊日期
        };
        if (
          ["1", "2"].includes(this.scoreRuleForm.goodsRangeType) &&
          this.scoreRuleForm.goodsItem.length <= 0
        ) {
          return this.$message.error("请选择适用商品");
        }
        if (
          this.scoreRuleForm.isMultSpecialDateScore &&
          this.scoreRuleForm.multVipLevelItem.length <= 0
        ) {
          return this.$message.error("请选择会员级别多倍积分");
        }
        if (
          this.scoreRuleForm.isMultSpecialDateScore &&
          this.scoreRuleForm.multSpecialDateItem.length <= 0
        ) {
          return this.$message.error("请选择时间范围");
        }
        if (
          ["1", "2"].includes(this.scoreRuleForm.shopRangeType) &&
          this.scoreRuleForm.shopItem.length <= 0
        ) {
          return this.$message.error("请选择门店");
        }
        if (
          ["1", "2"].includes(this.scoreRuleForm.vipLevelRangeType) &&
          this.scoreRuleForm.vipLevelItem.length <= 0
        ) {
          return this.$message.error("请选择会员级别");
        }
        //适用渠道字段转换赋值
        this.scoreRuleForm.AllowOfflineShop.forEach((v) => {
          if (v === "0") this.scoreRuleForm.isAllowOfflineShop = true; //线下门店
          if (v === "1") this.scoreRuleForm.isAllowOnlineShop = true; //线上商城
        });
        this.loading = true;
        if (this.scoreRuleForm.billId) {
          const res = await updateOrder(this.scoreRuleForm, this.urlUpdate);
          this.$message.success("保存成功");
          if (isAdd) {
            await this.reset();
            this.getBillNoApi();
          } else {
            this.scoreRuleForm.billStatus = res.data.billStatus;
          }
        } else {
          const res = await addOrder(this.scoreRuleForm, this.urlAdd);
          this.$message.success("保存成功");
          if (isAdd) {
            await this.reset();
            this.getBillNoApi();
          } else {
            this.scoreRuleForm.billStatus = res.data.billStatus;
            this.scoreRuleForm.billId = res.data.billId;
          }
        }
        this.loading = false;
      } catch (err) {
        this.$message.error("请把带有红星的必填项输入完整!");
      }
    },
    // 页面重置
    reset() {
      this.scoreRuleForm = {
        AllowOfflineShop: ["0", "1"], //适用渠道
        isAllowOfflineShop: false, //适用渠道(线下门店)
        isAllowOnlineShop: false, //适用渠道(线上商城)
        billNo: undefined,
        billStatus: "",
        datetime: [],
        vipLevelRangeType: "0", //适用会员
        shopRangeType: "0", //适用门店
        goodsRangeType: "0", //适用商品
        birthdayScoreMode: "0", //会员生日多倍模式
        multScoreMode: "0", //多倍积分取值规则(0:多倍积分互斥1:多倍积分叠加)
        goodsItem: [], //会员积分规则积分适用商品的请求对象
        exclGoodsItem: [], //会员积分规则积分例外商品的请求对象
        multExclGoodsItem: [], //会员积分规则多倍积分例外商品的请求对象
        multVipLevelItem: [], //会员积分规则多倍积分会员等级的请求对象
        vipLevelItem: [], //会员积分规则适用会员级别的请求对象
        shopItem: [], //适用门店
        multSpecialDateItem: [], //特殊日期表格信息
        isDeductNoScore: false,
        isLargessNoScore: false,
        isPromoNoScore: false,
        isUsableNoScore: false,
        isMultVipLevelScore: false,
        isMultSpecialDateScore: false,
        isMultBirthdayScore: false,
      };
      this.multSpecialDateItem = []; //虚假特殊日期表格信息
      this.resetForm("form");
    },
    //获取会员充值规则编号
    async getBillNoApi() {
      this.loading = true;
      const res = await getBillNo(this.billType);
      this.$set(this.scoreRuleForm, "billNo", res);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  min-height: calc(100vh - 84px);
}
::v-deep .cardContent {
  padding: 20px 20px 10px;
}
.el-form-item {
  margin-bottom: 10px;
}
//基本资料
.baseInfoBody {
  display: flex;
  .baseInput {
    width: 200px;
    margin: 0 20px 0 10px;
  }
  .remarkinput {
    width: 300px;
  }
}

//多选框
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #606266;
}
//输入框
::v-deep .el-input__inner {
  height: 30px;
}
//选择商品
::v-deep .el-button--medium {
  padding: 6px 20px;
}
</style>
