<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent"> </TablePage>
    <!-- 新增/编辑对话框 -->
    <VipLevelDialog :vipBrandId='vipBrandId' ref="vipLevelDialog" @getList="$refs.tablePage.getList()"></VipLevelDialog>
  </div>
</template>

<script>
import {
  vipBrandList,
  vipBrandSave,
  vipBrandUpdate,
  vipBrandDetail,
  vipBrandRemove,
  restoreVipCardBrand,
} from '@/api/vip/wxService/wxVipcard' //微信会员包
import TablePage from "@/components/tablePage"; //表格页面组件
import VipLevelDialog from "@/views/vip/base/level/components/vipLevelDialog.vue";
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { listlevel, dellevel, restoreVipCardLevel } from "@/api/vip/base/level.js";
export default {
  name: "vipLevel",
  dicts: ["common_status"],
  components: { TablePage, CDialog, VipLevelDialog },
  data() {
    return {
      vipBrandId: "",
      options: {
        mutiSelect: true, // 多选
        loading: false,
        tree: {
          body: {
            vipBrandId: '',
          },
          isAddBtn: true,
          defaultExpandAll: true,
          dialogOptions: {
            api: {
              add: vipBrandSave,
              update: vipBrandUpdate,
              detail: vipBrandDetail,
              remove: vipBrandRemove,
              list: vipBrandList,
              restore:restoreVipCardBrand
            },
            title: "会员品牌",
            type: "VipBand",
          },
          title: "会员品牌",
          value: "vipBrandId",
          label: "vipBrandName",
          setData: (e) => ({ vipBrandId: e.vipBrandId || "" }), // 后端要求全部分类为空,
          getListApi: vipBrandList,
        },
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        title: "会员级别列表",
        getListApi: listlevel,
        listNo: true, // 序号
        check: [], // 选中数据
        rowKey: "vipLevelId",
        //搜索
        search: [
          {
            type: "filters",
            tip: "级别编码/级别名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "vipLevelNos", label: "级别编码" },
              { filter: "vipLevelNames", label: "级别名称" },
            ],
          },
          {
            type: "button",
            tip: "查询",
            btnType: "primary",
            click: "search",
            isRestore: true,
          },
        ],
        //按钮
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中会员级别吗？",
            btnType: "dropdown",
            trigger: "click", // 要求
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: restoreVipCardLevel,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        //表格
        columns: [
          {
            prop: "vipLevelNo",
            label: "级别编码",
            type: "link",
            click: "update",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "vipLevelName",
            label: "级别名称",
            minWidth: 150,
            align: "center",
          },
          {
            prop: "priceCaseName",
            label: "会员价",
            minWidth: 120,
            align: "center",
          },
          {
            type: "icons",
            prop: "isCanShopAdd",
            label: "门店显示",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isCanFill",
            label: "储值",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isCanScore",
            label: "积分",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isCanDiscount",
            label: "折扣",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isCanMerge",
            label: "另类卡合并",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isWxVipLevel",
            label: "微会员级别",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            type: "icons",
            prop: "isDefaultWxVipLevel",
            label: "默认微会员级别",
            minWidth: 120,
            align: "center",
            formatter: (propValue) => ({
              icon: propValue ? "el-icon-check" : "el-icon-close",
              style: `color: ${propValue ? "#41bb41" : "#ff4949"
                } ; font-size: 20px; font-weight: 700;`,
            }),
          },
          {
            prop: "statusName",
            label: "状态",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "begDate",
            label: "生效日期",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "endDate",
            label: "结束日期",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "zjm",
            label: "助记码",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
            align: "center",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 110,
            align: "center",
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
            align: "center",
          },
        ],
        list: [],
      },
      delFlag: null, //删除标志
      normal: 0, //状态正常
      disable: 1, //状态停用
      total: 0,
      title: "新增会员等级",
      loading: false, //表格加载
      multiple: true, //删除
      //   右边表格
      tableData: [],
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        vipLevelName: null,
      },
    };
  },
  methods: {
    handleEvent(type, row) {
      if (type=='getListed') {
      console.log('getListed',type,this.options);
      }
      switch (type) {
        case "add":
          this.vipBrandId=this.options?.tree?.body?.vipBrandId;
          this.$refs.vipLevelDialog.initializaData();
          break;
        case "update":
          this.$refs.vipLevelDialog.initializaData(row.vipLevelId);
          break;
        case "del":
          this.handleDelete();
          break;
        default:
          break;
      }
    },
    // 删除按钮
    async handleDelete() {
      this.$confirm("确定删除此会员级别?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            await dellevel(this.options.check.map((x) => x.vipLevelId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) { }
        })
        .catch(() => { });
    },
    //当选择项发生变化时会触发
    handleSelectionChange(selection) {
      const delFlagStatus = selection.some((v) => v.delFlag == "1");
      this.delFlag = delFlagStatus; //删除标志
      this.ids = selection.map((item) => item.vipLevelId);
      this.name = selection.map((item) => item.vipLevelName);
      this.multiple = !selection.length;
    },
  },
};
</script>

<style lang="scss" scoped>
//输入框
.vipInput {
  width: 89%;
}
.searchInput {
  width: 68%;
  margin-right: 2%;
}
//整体盒子

.tableBody {
  padding: 30px;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

//顶部操作
.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
