var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-x", attrs: { id: "wrap" } },
    [
      _c(
        "div",
        { staticClass: "left" },
        [
          _c("categoryTreeCard", {
            attrs: {
              categoryTreeTitle: "会员级别",
              isEditBtn: true,
              boxHeight: _vm.boxHeight,
              boxInternalHeight: _vm.boxInternalHeight,
              categoryTreeOptions: _vm.vipCardTreeOptions,
              defaultProps: _vm.defaultProps,
            },
            on: {
              treeNodeId: _vm.getTreeNodeId,
              appendTree: _vm.appendTree,
              editTree: _vm.editTree,
              removeTree: _vm.removeTree,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("TablePage", {
            ref: "tablePage",
            staticClass: "tablepage",
            on: { handleEvent: _vm.handleEvent },
            model: {
              value: _vm.options,
              callback: function ($$v) {
                _vm.options = $$v
              },
              expression: "options",
            },
          }),
        ],
        1
      ),
      _c("add-dialog", {
        ref: "addDialog",
        on: {
          getList: function ($event) {
            return _vm.$refs.tablePage.getList()
          },
        },
      }),
      _c("batch-dialog", {
        ref: "batchDialog",
        on: {
          getList: function ($event) {
            return _vm.$refs.tablePage.getList()
          },
        },
      }),
      _c("batch-edit", {
        ref: "batchEdit",
        on: {
          getList: function ($event) {
            return _vm.$refs.tablePage.getList()
          },
        },
      }),
      _c("VipLevelDialog", {
        ref: "vipLevelDialog",
        on: { getList: _vm.getVipLevelData },
      }),
      _c("Dialog", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }