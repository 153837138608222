var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap x-f", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { staticClass: "vipLedger", attrs: { cardTitle: "会员账本" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "padd10" },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    staticStyle: { width: "100%" },
                    attrs: {
                      data: _vm.tableData,
                      "tooltip-effect": "dark",
                      border: "",
                      "show-summary": "",
                      "summary-method": _vm.getSummaries,
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        width: "60",
                        type: "index",
                      },
                    }),
                    _vm._l(_vm.tableColumn, function (item, index) {
                      return _c("el-table-column", {
                        key: index,
                        attrs: {
                          label: item.label,
                          align: "center",
                          prop: item.prop,
                          minWidth: item.minWidth,
                        },
                      })
                    }),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }