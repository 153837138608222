var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "96px" },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              isExamineBtn: true,
              isStopBtn: true,
              id: "topOperatingButton",
              showAudit: _vm.form.billStatus != "0",
              showIsStop: _vm.form.billStatus != "2",
            },
            on: {
              submitForm: function ($event) {
                return _vm.submitForm(false)
              },
              addBill: function ($event) {
                return _vm.submitForm(true)
              },
              auditBill: _vm.auditBill,
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
              isStop: _vm.getEnable,
            },
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.form.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "baseInfoBody" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "billNo",
                          label: "方案编号",
                          "label-width": "80px",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "baseInput",
                          attrs: { disabled: true, placeholder: "方案编号" },
                          model: {
                            value: _vm.form.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billNo", $$v)
                            },
                            expression: "form.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "billName",
                          label: "方案名称",
                          "label-width": "80px",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "baseInput",
                          attrs: {
                            disabled: _vm.disabled,
                            placeholder: "方案名称",
                          },
                          model: {
                            value: _vm.form.billName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billName", $$v)
                            },
                            expression: "form.billName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          prop: "datetime",
                          label: "有效日期",
                          "label-width": "80px",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            type: "datetimerange",
                            "start-placeholder": "开始日期",
                            "end-placeholder": "结束日期",
                            "default-time": ["00:00:00", "23:59:59"],
                            "value-format": "yyyy-MM-dd HH:mm:ss",
                            disabled: _vm.disabled,
                            "picker-options": _vm.pickerOptions,
                          },
                          model: {
                            value: _vm.form.datetime,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "datetime", $$v)
                            },
                            expression: "form.datetime",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "方案备注", "label-width": "80px" } },
                      [
                        _c("el-input", {
                          staticClass: "remarkinput",
                          attrs: {
                            disabled: _vm.disabled,
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                          },
                          model: {
                            value: _vm.form.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "billRemark", $$v)
                            },
                            expression: "form.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "积分兑换储值金规则" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-x" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "eachScore", "label-width": "1px" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth marR10",
                          attrs: { disabled: _vm.disabled, size: "mini" },
                          model: {
                            value: _vm.form.eachScore,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "eachScore", $$v)
                            },
                            expression: "form.eachScore",
                          },
                        }),
                        _c("span", [_vm._v("积分兑换")]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { prop: "eachMoney", "label-width": "10px" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: { disabled: _vm.disabled, size: "mini" },
                          model: {
                            value: _vm.form.eachMoney,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "eachMoney", $$v)
                            },
                            expression: "form.eachMoney",
                          },
                        }),
                        _c("span", { staticClass: "marL10" }, [_vm._v("元,")]),
                        _c("span", [_vm._v("每次兑换必须是积分")]),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: { prop: "eachMultiple", "label-width": "10px" },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth marR10",
                          attrs: { disabled: _vm.disabled, size: "mini" },
                          model: {
                            value: _vm.form.eachMultiple,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "eachMultiple", $$v)
                            },
                            expression: "form.eachMultiple",
                          },
                        }),
                        _c("span", [_vm._v("的倍数")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用门店" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "rangeShop" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用门店", "label-width": "70px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.form.shopRangeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "shopRangeType", $$v)
                              },
                              expression: "form.shopRangeType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.fill_shop_range_type,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.shopRangeType == "1" ||
                              _vm.form.shopRangeType == "2",
                            expression:
                              "form.shopRangeType == '1' || form.shopRangeType == '2'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "marB10",
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openShopDialog", {
                                  curTable: "shopOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定门店资料")]
                        ),
                        _c("EditTable", {
                          attrs: { options: _vm.shopOptions },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "适用会员" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "rangeVip" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "适用会员", "label-width": "70px" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            attrs: { disabled: _vm.disabled },
                            model: {
                              value: _vm.form.vipLevelRangeType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "vipLevelRangeType", $$v)
                              },
                              expression: "form.vipLevelRangeType",
                            },
                          },
                          _vm._l(
                            _vm.dict.type.fill_vip_level_range_type,
                            function (dict) {
                              return _c(
                                "el-radio",
                                {
                                  key: dict.value,
                                  attrs: { label: dict.value },
                                },
                                [_vm._v(_vm._s(dict.label))]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.form.vipLevelRangeType == "1" ||
                              _vm.form.vipLevelRangeType == "2",
                            expression:
                              "form.vipLevelRangeType == '1' || form.vipLevelRangeType == '2'",
                          },
                        ],
                      },
                      [
                        _c(
                          "el-button",
                          {
                            staticClass: "marT10 marB10",
                            attrs: { disabled: _vm.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.handleEvent("openVipLevelDialog", {
                                  curTable: "vipLevelOptions",
                                })
                              },
                            },
                          },
                          [_vm._v("指定会员等级")]
                        ),
                        _c("EditTable", {
                          attrs: { options: _vm.vipLevelOptions },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }