var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "x-f" },
    [
      _c(
        "el-form-item",
        { attrs: { label: "单据编号", required: "" } },
        [
          _c("el-input", {
            staticStyle: { width: "160px" },
            attrs: {
              size: "mini",
              disabled: _vm.disabled,
              placeholder: "单据编号",
            },
            model: {
              value: _vm.documentNum,
              callback: function ($$v) {
                _vm.documentNum = $$v
              },
              expression: "documentNum",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "单据日期", required: "" } },
        [
          _c("el-date-picker", {
            staticStyle: { width: "160px" },
            attrs: {
              size: "mini",
              type: "date",
              placeholder: "单据日期",
              disabled: _vm.disabled,
              "value-format": "yyyy-MM-dd",
            },
            model: {
              value: _vm.documentDate,
              callback: function ($$v) {
                _vm.documentDate = $$v
              },
              expression: "documentDate",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }