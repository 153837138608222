var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vipLevelDialog" },
    [
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 750, showDialog: _vm.openDialog },
        on: {
          "update:showDialog": function ($event) {
            _vm.openDialog = $event
          },
          "update:show-dialog": function ($event) {
            _vm.openDialog = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "form",
                    attrs: {
                      model: _vm.levelForm,
                      rules: _vm.rules,
                      "label-width": "110px",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "vipLevelNo",
                            attrs: { label: "级别编码", prop: "vipLevelNo" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vipInput",
                              attrs: {
                                placeholder: "请输入级别编码(1-20字符)",
                                maxlength: "20",
                                size: "mini",
                              },
                              model: {
                                value: _vm.levelForm.vipLevelNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "vipLevelNo", $$v)
                                },
                                expression: "levelForm.vipLevelNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "vipLevelName",
                            attrs: { label: "级别名称", prop: "vipLevelName" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "vipInput",
                              staticStyle: {
                                width: "calc(100% - 30px)",
                                "margin-right": "5px",
                              },
                              attrs: {
                                placeholder: "请输入级别名称(1-40字符)",
                                maxlength: "40",
                                size: "mini",
                              },
                              model: {
                                value: _vm.levelForm.vipLevelName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "vipLevelName", $$v)
                                },
                                expression: "levelForm.vipLevelName",
                              },
                            }),
                            _c("el-tooltip", { attrs: { placement: "top" } }, [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [_vm._v("tip:微信卡包等级名称不能超过4个字符")]
                              ),
                              _c("i", { staticClass: "el-icon-question" }),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "price",
                            attrs: { prop: "price", label: "价格方案" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择价格方案",
                                  filterable: "",
                                  clearable: "",
                                  size: "mini",
                                },
                                model: {
                                  value: _vm.levelForm.priceCaseId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.levelForm, "priceCaseId", $$v)
                                  },
                                  expression: "levelForm.priceCaseId",
                                },
                              },
                              _vm._l(_vm.priceCaseList, function (item) {
                                return _c("el-option", {
                                  key: item.priceCaseId,
                                  attrs: {
                                    label: item.priceCaseName,
                                    value: item.priceCaseId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { key: "isCanShopAdd", attrs: { label: "门店显示" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.levelForm.isCanShopAdd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.levelForm, "isCanShopAdd", $$v)
                                  },
                                  expression: "levelForm.isCanShopAdd",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { key: "isCanFill", attrs: { label: "储值" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.levelForm.isCanFill,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.levelForm, "isCanFill", $$v)
                                  },
                                  expression: "levelForm.isCanFill",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { key: "isCanScore", attrs: { label: "积分" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.levelForm.isCanScore,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.levelForm, "isCanScore", $$v)
                                  },
                                  expression: "levelForm.isCanScore",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { key: "isCanDiscount", attrs: { label: "折扣" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.levelForm.isCanDiscount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.levelForm,
                                      "isCanDiscount",
                                      $$v
                                    )
                                  },
                                  expression: "levelForm.isCanDiscount",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          { key: "isCanMerge", attrs: { label: "另类卡合并" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.levelForm.isCanMerge,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.levelForm, "isCanMerge", $$v)
                                  },
                                  expression: "levelForm.isCanMerge",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商城默认级别" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                attrs: {
                                  disabled: !_vm.levelForm.isWxVipLevel,
                                },
                                model: {
                                  value: _vm.levelForm.isDefaultWxVipLevel,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.levelForm,
                                      "isDefaultWxVipLevel",
                                      $$v
                                    )
                                  },
                                  expression: "levelForm.isDefaultWxVipLevel",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: true } }, [
                                  _vm._v("是"),
                                ]),
                                _c("el-radio", { attrs: { label: false } }, [
                                  _vm._v("否"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            key: "begDate",
                            attrs: { label: "生效日期", prop: "begDate" },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                clearable: "",
                                type: "date",
                                placeholder: "请选择生效日期",
                                format: "yyyy-MM-dd",
                                "value-format": "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.levelForm.begDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "begDate", $$v)
                                },
                                expression: "levelForm.begDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            key: "endDate",
                            attrs: { label: "结束日期", prop: "endDate" },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                clearable: "",
                                type: "date",
                                placeholder: "请选择结束日期",
                                "value-format": "yyyy-MM-dd",
                                format: "yyyy-MM-dd",
                              },
                              model: {
                                value: _vm.levelForm.endDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.levelForm, "endDate", $$v)
                                },
                                expression: "levelForm.endDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        key: "remark",
                        staticClass: "w100",
                        attrs: { label: "备注" },
                      },
                      [
                        _c("el-input", {
                          attrs: {
                            type: "textarea",
                            autosize: { minRows: 2, maxRows: 4 },
                            placeholder: "请输入备注(1-80字符)",
                            maxlength: "80",
                          },
                          model: {
                            value: _vm.levelForm.remark,
                            callback: function ($$v) {
                              _vm.$set(_vm.levelForm, "remark", $$v)
                            },
                            expression: "levelForm.remark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    on: {
                      click: function ($event) {
                        _vm.openDialog = false
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addlevel(true)
                      },
                    },
                  },
                  [_vm._v("保存并新增")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addlevel(false)
                      },
                    },
                  },
                  [_vm._v("保存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }