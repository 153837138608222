<template>
  <div class="wrap" v-loading="loading" id="wrap">
    <el-form ref="form" :model="options" :rules="rules" label-width="96px">
      <topOperatingButton
        id="topOperatingButton"
        :isSubmitBtn="false"
        :isSubmitAddBtn="false"
        @getQuit="$router.go(-1)"
        :isAuditBillBtn="false"
      >
        <template v-slot:specialDiyBtn>
          <el-button size="mini" @click="submitForm(false)" type="primary">
            兑换
          </el-button>
          <el-button size="mini" @click="submitForm(true)" type="primary">
            兑换并新增
          </el-button>
          <el-button size="mini" @click="handleEvent('batchDel')">
            批量删除
          </el-button>
        </template>
      </topOperatingButton>
      <cardTitleCom
        cardTitle="基本信息"
        id="basicInformation"
        :billStatus="options.billStatus"
      >
        <template slot="cardContent">
          <div class="x-f marT10">
            <el-form-item label="单据编号" required>
              <el-input
                class="inputWidth"
                size="mini"
                :disabled="true"
                v-model="options.billNo"
                placeholder="单据编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="单据日期" required>
              <el-date-picker
                class="inputWidth"
                size="mini"
                v-model="options.billDate"
                type="date"
                placeholder="单据日期"
                :disabled="['2', '3'].includes(options.billStatus)"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              :label="item.label"
              v-for="(item, index) in options.selects"
              :key="index"
              :prop="item.option.value"
            >
              <SelectRemote
                v-model="options[item.option.value]"
                :option="item.option"
                :filterable="item.option.filterable || false"
                :multiple="item.option.multiple || false"
                :clearable="item.option.clearable || false"
                :collapse-tags="item.option['collapse-tags']"
                :placeholder="item.placeholder || `选择${item.label}`"
                :disabled="['2', '3'].includes(options.billStatus)"
              />
            </el-form-item>
            <el-form-item label="可用积分">
              <span>{{ options.usableScore }}</span>
            </el-form-item>
          </div>
          <div class="x-f marT10">
            <el-form-item label="备注">
              <el-input
                size="mini"
                class="inputWidth"
                v-model="options.billRemark"
                type="textarea"
                placeholder="备注长度介于 1 和 80 字符之间"
                maxlength="80"
                :autosize="{ minRows: 1, maxRows: 1 }"
                :disabled="['2', '3'].includes(options.billStatus)"
              ></el-input>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="会员状态调整列表">
        <template slot="cardContent">
          <div class="table">
            <!-- 可编辑表格 -->
            <EditTable :options="options" @handleEvent="handleEvent" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
    <!-- 弹窗 -->
    <Dialog
      ref="tablePage"
      :options.sync="dialogOptions"
      @handleEvent="handleEvent"
    />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import EditTable from '@/components/editTable' //可编辑表格
import Dialog from '@/components/Dialog'
import topOperatingButton from '@/views/components/topOperatingButton' //顶部按钮
import { getBillNo } from '@/api/codeRule'
import {
  saveScoreExchange,
  updateScoreExchangeStatus,
  getScoreExchangeDetail
} from '@/api/vip/bill/scoreExchange'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { scoreGoodsList } from '@/api/goods/goods' //会员积分商品
import { deepCopy,fcount } from '@/utils'
import { getFormattedDate } from '@/utils/newDate'

export default {
  // 会员积分兑换
  name: 'ScoreExchangeDetail',
  dicts: [
    'vip_card_status',
    'common_status',
    'pricing_mode',
    'App_is_manage_stock',
    'goods_type',
    'goods_type'
  ],
  components: {
    cardTitleCom,
    EditTable,
    topOperatingButton,
    Dialog,
    SelectRemote
  },
  watch: {
    'options.billStatus': {
      handler (val) {
        const disabledColumns = ['2', '3'].includes(val)
        this.options.columns[0].disabled = disabledColumns
        if(disabledColumns){
          this.options.columns.splice(7,1)
          this.options.columns[7].disabled = disabledColumns
        }else this.options.columns[8].disabled = disabledColumns
        this.options.status = disabledColumns
      },
      immediate: true
    },
    'options.vipId': {
      handler (val) {
        if (val) {
          this.options.columns[0].option.buttons[0].option.formData.queryParams.vipId = val
          this.options.columns[0].option.remoteBody.vipId = val
        }
      },
      immediate: true
    },
  },
  data () {
    return {
      loading: false, //遮罩层
      //表格配置
      options: {
        billDate: '', // 单据日期（当前日期）
        billNo: undefined, // 单据编号
        billId: undefined, //单据id
        vipId: undefined, //会员id
        shopId: undefined, //门店id
        mutiSelect: true, //是否多选
        status: undefined, //状态
        usableScore: 0, //会员可用积分
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        tableHeight: 0, //表格高度
        retainColumn: true, //保留表格最后一行
        rowKey: 'billId',
        selects: [
          this.$select({
            key: 'shopInfo',
            option: {
              option: {
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择门店',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: {
                        ...this.$dialog({ key: 'shop' }),
                        table: {
                          ...this.$dialog({ key: 'shop' }).table,
                          radioSelect: true,
                          mutiSelect: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'vip',
            option: {
              option: {
                change: (propValue, row) => {
                  if (row) {
                    console.log(row, 'row')
                    this.options.usableScore = row.usableScore
                  }
                },
                tableChange: (propValue, row) => {
                  if (row) {
                    this.options.usableScore = row.check.usableScore
                  }
                },
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择会员',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: {
                        ...this.$dialog({ key: 'vip' }),
                        table: {
                          ...this.$dialog({ key: 'vip' }).table,
                          radioSelect: true,
                          mutiSelect: false
                        }
                      }
                    }
                  }
                ]
              }
            }
          })
        ],
        columns: [
          {
            prop: 'goodsNo',
            label: '商品编码',
            type: 'remoteSelect',
            minWidth: 200,
            align: 'center',
            option: this.$select({
              key: 'listGoods',
              option: {
                option: {
                  remote: scoreGoodsList,
                  remoteBody: { vipId: '00' },
                  change: (propValue, row) => {
                    if (!this.options.vipId) {
                      this.options.list = [{}]
                      return this.$message.warning('请先选择会员')
                    }
                    if (row && this.options.tableIndex != undefined) {
                      this.handleEvent('getSelectData', { check: [row] })
                    }
                  },
                  tableChange: (propValue, row) => {
                    this.handleEvent('getSelectData', row)
                  },
                  buttons: [
                    {
                      type: 'more',
                      option: {
                        title: '选择商品',
                        width: 1250,
                        type: 'TreeAndTable',
                        formData: this.$dialog({
                          key: 'goods',
                          option: {
                            treeApi: undefined,
                            queryParams: {
                              vipId: '00'
                            },
                            table: {
                              ...this.$dialog({ key: 'goods' }).table,
                              list: scoreGoodsList
                            }
                          }
                        })
                      }
                    }
                  ]
                }
              }
            }).option,
            disabled: false,
            rules: true
          },
          {
            prop: 'goodsName',
            label: '商品名称',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'barcode',
            label: '条码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'zjm',
            label: '助记码',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'goodsSpec',
            label: '规格',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'unitName',
            label: '单位',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'equalScore',
            label: '等价积分',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'scoreGoodsQry',
            label: '可兑数量',
            minWidth: 120,
            align: 'center'
          },
          {
            prop: 'unitQty',
            label: '数量',
            type: 'input',
            input: 'sumScore',
            minWidth: 150,
            align: 'center',
            disabled: false,
            rules: true
          },
          {
            prop: 'equalScoreTotal',
            label: '等价总积分',
            minWidth: 120,
            align: 'center'
          }
        ],
        summary: ['unitQty', 'equalScoreTotal']
      },
      //弹窗配置
      dialogOptions: {
        title: '选择商品',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      },
      pageH: 0, //页面高度
      btnsH: 0, //搜索框高度
      basicH: 0, //基础框高度
      paginationH: 50, //分页高度
      rules: {
        billNo: [
          {
            required: true,
            message: '请输入单据编号',
            trigger: ['blur', 'change']
          }
        ],
        billDate: [
          { required: true, message: '请输入单据日期', trigger: 'blur' }
        ],
        vipId: [
          {
            required: true,
            message: '请选择会员',
            trigger: ['blur', 'change']
          }
        ],
        shopId: [
          {
            required: true,
            message: '请选择门店',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  activated () {
    this.handleResize()
    this.getDetail()
  },
  created () {
    this.handleResize()
  },
  mounted () {
    window.addEventListener('resize', this.handleResize)
    this.getDetail()
  },
  beforeDestroy () {
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    //获取详情
   async getDetail () {
      this.reset()
        console.log(this.$route.query)
        if (this.$route.query.type === 'Update' && this.$route.query.billId) {
          this.loading = true
          try {
            //获取方案详情
            let { data } = await getScoreExchangeDetail(
              this.$route.query.billId
            )
            let newData = deepCopy(data)
            this.options.columns[0].option.showItem = deepCopy(
              newData.shopBillPosRetailDetailReq
            )

            //方案赋值
            this.options = {
              ...this.options,
              ...newData,
              list: deepCopy(newData.shopBillPosRetailDetailReq)
            }
          } catch (err) {}
          this.loading = false
        } else {
          this.getBillno()
        }
    },
    //自适应获取高度
    async handleResize () {
      setTimeout(() => {
        this.pageH = document.getElementById('wrap').offsetHeight
        this.btnsH = document.getElementById('topOperatingButton').offsetHeight
        this.basicH = document.getElementById('basicInformation').offsetHeight
        this.options.tableHeight =
          this.pageH - this.btnsH - this.basicH - this.paginationH - 68
      }, 10)
    },
    async handleEvent (type, row) {
      switch (type) {
        case 'clickGoods':
          if (['2', '3'].includes(this.options.billStatus)) return
          if (!this.options.vipId) return this.$message.warning('请先选择会员')
          //弹窗配置
          this.dialogOptions = {
            index: row.index,
            title: '选择积分商品',
            width: 1250,
            show: true,
            type: 'TreeAndTable',
            formData: this.$dialog({ key: 'vipScoreGoods' })
          }
          //表格过滤入参
          this.dialogOptions.formData.table.pagination.vipId = this.options.vipId
          console.log(this.$dialog({ key: 'vipScoreGoods' }), '666')
          break
        case 'getSelectData':
          if (!row || !row.check || row.check.length <= 0) return
          const { check } = row
          this.options.columns[0].option.showItem = check
          let tableIndex = this.options.tableIndex
          this.options.tableIndex = undefined
          this.options.list.splice(tableIndex, 1, ...check)
          //去重
          break
        case 'sumScore':
          if (!row.goodsId) return
          console.log(
            Number(this.options.list[this.options.tableIndex].unitQty)
          )
          console.log(
            Number(this.options.list[this.options.tableIndex].scoreGoodsQry)
          )
          if (
            Number(this.options.list[this.options.tableIndex].scoreGoodsQry) <
            Number(this.options.list[this.options.tableIndex].unitQty)
          ) {
            this.$message.warning('兑换数量超出可兑数量')
            this.options.list[
              this.options.tableIndex
            ].unitQty = this.options.list[this.options.tableIndex].scoreGoodsQry
          }
          this.options.list[this.options.tableIndex].equalScoreTotal =
            Number(this.options.list[this.options.tableIndex].equalScore) *
            Number(this.options.list[this.options.tableIndex].unitQty)
          break
        case 'batchDel': //批量删除
          if (['2', '3'].includes(this.options.billStatus)) {
            return this.$message.warning('请操作未审核的方案')
          }
          if (!this.options.check.length) {
            return this.$message.warning('请选择要操作的数据项')
          }
          this.$confirm('确定删除选中的数据?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              let vipIds = this.options.check.map(item => item.goodsId)
              this.options.list = this.options.list.filter(item => {
                return this.options.check.find(
                  i => i.goodsId == item.goodsId && i.unitId == item.unitId
                )
                  ? false
                  : true
              })
              if (this.options.list.length <= 0) this.options.list = [{}]
            })
            .catch(() => {})
          break
        case 'tableIndex':
          this.options.tableIndex = row
          break
        default:
          break
      }
    },
    // 保存
    async submitForm (isAdd) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let form = {
            billNo: this.options.billNo, // 单据号
            billId: this.options.billId, // 单据ID
            billDate: this.options.billDate, // 单据时间
            vipId: this.options.vipId,
            shopId: this.options.shopId,
            shopBillPosRetailDetailReq: this.options.list
          }
          form.shopBillPosRetailDetailReq = form.shopBillPosRetailDetailReq.filter(
            item => item.goodsId
          )
          if (form.shopBillPosRetailDetailReq.length <= 0)
            return this.$message.warning('请选择商品')
          //兑换积分不能大于可用积分
          let exchangeScore = 0
          form.shopBillPosRetailDetailReq.map(item => {
            exchangeScore += Number(item.equalScoreTotal)
            return ({
              ...item,
              unitPrice:item.salePrice,
              unitMoney:fcount([item.salePrice,item.unitQty], '*' )
            })
          })
          if(exchangeScore > this.options.usableScore) return this.$message.error('兑换积分大于可用积分')
          this.loading = true
          this.$confirm('确定兑换?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(async () => {
              try {
                const { data } = await saveScoreExchange(form)
                this.$set(this.options, 'billStatus', data.billStatus)
                this.$set(this.options, 'billId', data.billId)
                this.$message.success('新增成功')
                //新增
                if (isAdd) {
                  this.reset()
                  this.getBillno()
                }
              } catch (err) {}
            })
            .catch(() => {})
          this.loading = false
        } else this.$message.error('请把带有红星的必填项输入完整!')
      })
    },
    // 获取订单编号
    async getBillno () {
      this.options.billNo = await getBillNo(150205)
      this.options.billDate = getFormattedDate(2,'-')
    },
    //数据初始化
    reset () {
      this.loading = false //遮罩层
      //表格配置
      this.options = {
        ...this.options,
        billDate: '', // 单据日期（当前日期）
        billNo: undefined, // 单据编号
        billId: undefined, //单据id
        vipId: undefined, //会员id
        shopId: undefined, //门店id
        mutiSelect: true, //是否多选
        status: undefined, //状态
        billStatus: undefined,
        list: [{}],
        check: [], //选中的数据
        curListIndex: -1, //当前表格下标
        tableCellLabel: '序号',
        tableHeight: 0, //表格高度
        retainColumn: true //保留表格最后一行
      }
      //弹窗配置
      this.dialogOptions = {
        title: '选择商品',
        width: 1250,
        show: false,
        type: 'TreeAndTable'
      }
      this.pageH = 0 //页面高度
      this.btnsH = 0 //搜索框高度
      this.basicH = 0 //基础框高度
      this.paginationH = 50 //分页高度
      this.resetForm('form')
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 40px 10px 10px 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
}
.table {
  padding: 10px;
}
</style>
