var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
      attrs: { id: "wrap" },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.options,
            rules: _vm.rules,
            "label-width": "96px",
          },
        },
        [
          _c("topOperatingButton", {
            attrs: {
              id: "topOperatingButton",
              isSubmitBtn: false,
              isSubmitAddBtn: false,
              isAuditBillBtn: false,
            },
            on: {
              getQuit: function ($event) {
                return _vm.$router.go(-1)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "specialDiyBtn",
                fn: function () {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm(false)
                          },
                        },
                      },
                      [_vm._v(" 兑换 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.submitForm(true)
                          },
                        },
                      },
                      [_vm._v(" 兑换并新增 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEvent("batchDel")
                          },
                        },
                      },
                      [_vm._v(" 批量删除 ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "cardTitleCom",
            {
              attrs: {
                cardTitle: "基本信息",
                id: "basicInformation",
                billStatus: _vm.options.billStatus,
              },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据编号", required: "" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            disabled: true,
                            placeholder: "单据编号",
                          },
                          model: {
                            value: _vm.options.billNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billNo", $$v)
                            },
                            expression: "options.billNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "单据日期", required: "" } },
                      [
                        _c("el-date-picker", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "单据日期",
                            disabled: ["2", "3"].includes(
                              _vm.options.billStatus
                            ),
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.options.billDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billDate", $$v)
                            },
                            expression: "options.billDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._l(_vm.options.selects, function (item, index) {
                      return _c(
                        "el-form-item",
                        {
                          key: index,
                          attrs: { label: item.label, prop: item.option.value },
                        },
                        [
                          _c("SelectRemote", {
                            attrs: {
                              option: item.option,
                              filterable: item.option.filterable || false,
                              multiple: item.option.multiple || false,
                              clearable: item.option.clearable || false,
                              "collapse-tags": item.option["collapse-tags"],
                              placeholder:
                                item.placeholder || "选择" + item.label,
                              disabled: ["2", "3"].includes(
                                _vm.options.billStatus
                              ),
                            },
                            model: {
                              value: _vm.options[item.option.value],
                              callback: function ($$v) {
                                _vm.$set(_vm.options, item.option.value, $$v)
                              },
                              expression: "options[item.option.value]",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _c("el-form-item", { attrs: { label: "可用积分" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.options.usableScore))]),
                    ]),
                  ],
                  2
                ),
                _c(
                  "div",
                  { staticClass: "x-f marT10" },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "备注" } },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            type: "textarea",
                            placeholder: "备注长度介于 1 和 80 字符之间",
                            maxlength: "80",
                            autosize: { minRows: 1, maxRows: 1 },
                            disabled: ["2", "3"].includes(
                              _vm.options.billStatus
                            ),
                          },
                          model: {
                            value: _vm.options.billRemark,
                            callback: function ($$v) {
                              _vm.$set(_vm.options, "billRemark", $$v)
                            },
                            expression: "options.billRemark",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "会员状态调整列表" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "table" },
                  [
                    _c("EditTable", {
                      attrs: { options: _vm.options },
                      on: { handleEvent: _vm.handleEvent },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("Dialog", {
        ref: "tablePage",
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }