<template>
  <div class="y-c">
    <el-form label-width="100px">
      <div class="y-c search">
        <div class="x-f">
          <el-form-item label="会员查询">
            <el-input
              style="width: 300px"
              v-model="val"
              property="会员号/卡号/手机号"
            ></el-input>
          </el-form-item>
          <el-form-item label-width="20px">
            <el-button type="primary" icon="el-icon-search" @click="search"
              >会员搜索</el-button
            >
          </el-form-item>
          <el-form-item label-width="20px" v-if="type === 1">
            <el-button type="primary" @click="batch">批量调整</el-button>
          </el-form-item>
        </div>
        <div v-if="type === 1">
          <el-form-item label-width="20px">
            <el-button type="primary">正常</el-button>
            <el-button type="primary">停用</el-button>
            <el-button type="primary">挂失</el-button>
            <el-button type="primary">作废</el-button>
            <el-button type="primary">退卡</el-button>
          </el-form-item>
        </div>
      </div>
      <div class="" style="padding: 0 60px">
        <div class="x-w">
          <el-form-item label="会员状态" v-if="type == 1">
            <el-input
              class="inputWidth"
              v-model="vipUser.cardNum"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="会员卡号">
            <el-input
              class="inputWidth"
              v-model="vipUser.cardNum"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="会员姓名">
            <el-input
              class="inputWidth"
              v-model="vipUser.vipName"
              disabled
            ></el-input>
          </el-form-item>
          <!--        </div>-->
          <!--        <div class="x-f">-->
          <el-form-item label="手机号">
            <el-input
              class="inputWidth"
              v-model="vipUser.cardNum"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="会员等级">
            <el-input
              class="inputWidth"
              v-model="vipUser.cardNum"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="剩余积分">
            <el-input
              class="inputWidth"
              v-model="vipUser.score"
              disabled
            ></el-input>
          </el-form-item>
          <!--        </div>-->
          <!--        <div class="x-f">-->
          <el-form-item label="储值余额">
            <el-input
              class="inputWidth"
              v-model="vipUser.cardNum"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="注册日期">
            <el-input
              class="inputWidth"
              v-model="vipUser.enrollDate"
              disabled
            ></el-input>
          </el-form-item>
          <!--        </div>-->
          <!--        <div class="x-f">-->
          <!--          <el-form-item label="注册日期">-->
          <!--            <el-input class="inputWidth" v-model="vipUser.cardNum" disabled></el-input>-->
          <!--          </el-form-item>-->
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { listlevel } from '@/api/vip/base/level' //会员等级
export default {
  name: 'vipSearch',
  props: {
    type: {
      type: Number,
      default: 0
    },
    vipUser: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      val: ''
      // vipUser: {
      //   cardNum: '',
      //   vipName: '',
      //   score: '',
      //   enrollDate: '',
      // }
    }
  },
  methods: {
    search () {
      this.$emit('search', this.val)
    },
    // 批量按钮
    batch(){
      this.$emit('batch')
    },
  }
}
</script>

<style lang="scss" scoped>
.search {
  margin-bottom: 30px;
}
.inputWidth {
  width: 200px;
}
</style>
