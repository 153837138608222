<template>
  <el-dialog :title="title" width="30%" :visible.sync="show" :before-close="handleClose">
    <div class="y-c">
      <slot name="batchForm"></slot>
      <div class="x-f-end" style="width: 100%">
        <el-button type="primary" @click="batchSubmit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>


export default {
  // 会员业务-批量操作弹框（通用）
  name: 'batchDialog',

  props: {
    show: {
      type: Boolean,
      default: false
    },
    title:{
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form:{

      }
    }
  },
  created() {
  },
  methods: {
    // 关闭弹框提示
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {
          })
    },
    batchSubmit(){

      this.$emit('batchSubmit')
    }


  }
}
</script>

<style lang="scss" scoped>

</style>
