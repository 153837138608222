<template>
  <!-- 批量修改弹窗 -->
  <c-dialog :title="title" :width="800" :showDialog.sync="open">
    <template #content>
      <el-form ref="form" :model="vipForm" label-width="80px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="会员级别" prop="vipLevelId">
            <el-select
              v-model="vipForm.vipLevelId"
              filterable
              placeholder="请选择会员级别"
              clearable
            >
              <el-option
                v-for="item in vipCardTreeOptions"
                :key="item.vipLevelId"
                :label="item.vipLevelName"
                :value="item.vipLevelId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="性别" prop="sex">
            <el-select
              v-model="vipForm.sex"
              placeholder="请选择性别"
              filterable
              clearable
            >
              <el-option
                v-for="dict in dict.type.sys_user_sex"
                :key="dict.value"
                :label="dict.label"
                :value="dict.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="12">
          <el-form-item label="生效日期" prop="begValidDate" key="begValidDate">
            <el-date-picker
              class="pickerTime"
              clearable
              v-model="vipForm.begValidDate"
              type="datetime"
              placeholder="请选择生效日期"
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker> </el-form-item
        ></el-col>
        <el-col :span="12">
          <el-form-item label="结束日期" prop="endValidDate" key="endValidDate">
            <el-date-picker
              class="pickerTime"
              clearable
              v-model="vipForm.endValidDate"
              type="datetime"
              placeholder="请选择结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker> </el-form-item
        ></el-col>
      </el-row>
    </el-form>
    </template>
    <template #buttonList>
      <el-button size="mini" class="marL10" @click="cancel">取 消</el-button>
      <el-button size="mini" type="primary" class="marL10" @click="submitForm">确 定</el-button>
    </template>
   </c-dialog>
</template>

<script>
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { batchUpdate } from '@/api/vip/base/vipCard' //会员资料
export default {
  name: 'batchEdit',
  dicts: ['sys_user_sex'],
  components:{CDialog},
  data () {
    return {
      open: false, //新增对话框
      title: '批量编辑', //对话框标题
      vipForm: {
        sex: undefined, //性别
        vipLevelId: undefined, //会员卡级别ID
        begValidDate: undefined, //开始生效日期
        endValidDate: undefined, //结束生效日期
        vipIds: [] //会员id
      },
      vipCardTreeOptions: [] //会员等级
    }
  },
  methods: {
    //对话框关闭前的回调
    handleClose (done) {
      if (
        this.vipForm.sex ||
        this.vipForm.vipLevelId ||
        this.vipForm.begValidDate ||
        this.vipForm.endValidDate
      ) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done()
          })
          .catch(_ => {})
      } else {
        done()
      }
    },
    // 表单重置
    reset () {
      this.vipForm = {
        sex: undefined, //性别
        vipLevelId: undefined, //会员卡级别ID
        begValidDate: undefined, //开始生效日期
        endValidDate: undefined, //结束生效日期
        vipIds: [] //会员id
      }
      this.resetForm('form')
    },

    /** 提交按钮 */
    async submitForm () {
      try{
        //判断生效日期不能大于结束日期
        if(this.vipForm.begValidDate && this.vipForm.endValidDate && new Date(this.vipForm.begValidDate).getTime() >= new Date(this.vipForm.endValidDate).getTime()){
            return this.$message.error('会员结束日期小于生效日期，请修改日期。')
        }
        await batchUpdate(this.vipForm)
        this.$modal.msgSuccess('批量修改成功')
        this.open = false
  
        this.$emit('getList')
      }catch(err){}

    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    }
  }
}
</script>

<style lang="scss" scoped>

.el-form{
  margin: 15px 15px 0;
  .el-select,.pickerTime{
    width: 100%;
  }
}
</style>
